import { faClose } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import {
  ButtonIcon,
  SchedulerForm,
  SchedulerFormHeader,
  SchedulerModalWrapper,
} from "../../styledComponents";
import SchedulerAddForm from "./SchedulerAddForm";
import SmallCalendar from "./SmallCalendar";

export default function SchedulerEventModal() {
  const { setShowSchedulerModal } = useContext(GlobalContext);

  return (
    <SchedulerModalWrapper>
      <SchedulerForm>
        <SchedulerFormHeader>
          <ButtonIcon
            icon={faClose}
            onClick={() => setShowSchedulerModal(false)}
          ></ButtonIcon>
        </SchedulerFormHeader>
        <SmallCalendar></SmallCalendar>
        <SchedulerAddForm></SchedulerAddForm>
      </SchedulerForm>
    </SchedulerModalWrapper>
  );
}
