import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Link, Routes } from "react-router-dom";
import DashboardContainer from "./components/DashboardContainer/Dashboard.jsx";
import { Availability } from "./components/AvailabilityContainer/Availability.jsx";
import { InOut } from "./components/InOutContainer/InOut.jsx";
import { Messages } from "./components/MessagesContainer/Messages.jsx";
import { Statistics } from "./components/StatisticsContainer/Statistics.jsx";
import { Salaries } from "./components/SalariesContainer/Salaries.jsx";
import { API } from "aws-amplify";
import logoWithText from "./images/ANLiTA_Purple.png";
import logoWithoutText from "./images/logo_blue.png";
import {
  LeftSide,
  Logo,
  SideNavBar,
  SideNavBarLink,
  HideSideBar,
  StyledIcon,
  SideNavBarText,
  Right,
  VerticalLine,
  Content,
} from "./styledComponents.js";
import {
  faDesktop,
  faCalendarDays,
  faFlag,
  faMessage,
  faWallet,
  faChartColumn,
  faClock,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import GlobalContext from "./context/GlobalContext.jsx";
import Scheduler from "./components/SchedulerContainer/Scheduler.jsx";
import {
  listEmployeesWithList,
  listStationsWithList,
} from "./graphql/custom-queries.js";

function App() {
  const dashboard = "Dashboard",
    scheduler = "Scheduler",
    availability = "Availability",
    messages = "Messages",
    saleries = "Salaries",
    statistics = "Statistics",
    inOut = "In / Out",
    hidePanel = "Hide panel";
  const [sideNavActive, setSideNavActive] = useState(dashboard);
  const [hideSidePanel, setHideSidePanel] = useState(false);
  const { setEmployees, setStations } = useContext(GlobalContext);
  const childDashboard = useRef(null);
  const setNavActiveInChild = () => {
    childDashboard.current && childDashboard.current.setNavActiveFromParent();
  };

  useEffect(() => {
    const path = window.location.href;
    let lastItem =
      path
        .substring(path.lastIndexOf("/") + 1)
        .charAt(0)
        .toUpperCase() + path.substring(path.lastIndexOf("/") + 2);
    lastItem && setSideNavActive(lastItem);
    async function fetchEmployees() {
      try {
        const apiData = await API.graphql({ query: listEmployeesWithList });
        const employeesFromAPI = apiData.data.listEmployees.items;
        setEmployees(employeesFromAPI);
      } catch (error) {}
    }
    async function fetchStations() {
      try {
        let stationApiData = await API.graphql({ query: listStationsWithList });
        let stationsFromApi = stationApiData.data.listStations.items;
        setStations(stationsFromApi);
      } catch (error) {}
    }
    fetchStations();
    fetchEmployees();
  }, [setEmployees, setStations]);
  return (
    <Content>
      <LeftSide hideSidePanel={hideSidePanel}>
        <Link to="/">
          <Logo
            src={hideSidePanel ? logoWithoutText : logoWithText}
            hideSidePanel={hideSidePanel}
            onClick={setNavActiveInChild}
          />
        </Link>
        <SideNavBar hideSidePanel={hideSidePanel}>
          <SideNavBarLink
            onClick={() => {
              setSideNavActive(dashboard);
            }}
            active={sideNavActive === dashboard ? "true" : undefined}
            top="true"
            to="/"
          >
            <StyledIcon icon={faDesktop} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {dashboard}
            </SideNavBarText>
          </SideNavBarLink>
          <SideNavBarLink
            onClick={() => {
              setSideNavActive(scheduler);
            }}
            active={sideNavActive === scheduler ? "true" : undefined}
            to="scheduler"
          >
            <StyledIcon icon={faCalendarDays} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {scheduler}
            </SideNavBarText>
          </SideNavBarLink>
          <SideNavBarLink
            onClick={() => {
              setSideNavActive(availability);
            }}
            active={sideNavActive === availability ? "true" : undefined}
            to="availability"
          >
            <StyledIcon icon={faFlag} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {availability}
            </SideNavBarText>
          </SideNavBarLink>
          <SideNavBarLink
            onClick={() => {
              setSideNavActive(messages);
            }}
            active={sideNavActive === messages ? "true" : undefined}
            to="messages"
          >
            <StyledIcon icon={faMessage} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {messages}
            </SideNavBarText>
          </SideNavBarLink>
          <SideNavBarLink
            onClick={() => {
              setSideNavActive(saleries);
            }}
            active={sideNavActive === saleries ? "true" : undefined}
            to="salaries"
          >
            <StyledIcon icon={faWallet} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {saleries}
            </SideNavBarText>
          </SideNavBarLink>
          <SideNavBarLink
            onClick={() => {
              setSideNavActive(statistics);
            }}
            active={sideNavActive === statistics ? "true" : undefined}
            to="statistics"
          >
            <StyledIcon icon={faChartColumn} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {statistics}
            </SideNavBarText>
          </SideNavBarLink>
          <SideNavBarLink
            onClick={() => {
              setSideNavActive(inOut);
            }}
            active={sideNavActive === inOut ? "true" : undefined}
            to="inOut"
          >
            <StyledIcon icon={faClock} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {inOut}
            </SideNavBarText>
          </SideNavBarLink>
          <HideSideBar
            onClick={() => {
              setHideSidePanel(!hideSidePanel);
            }}
          >
            <StyledIcon icon={hideSidePanel ? faChevronRight : faChevronLeft} />
            <SideNavBarText hideSidePanel={hideSidePanel}>
              {hidePanel}
            </SideNavBarText>
          </HideSideBar>
        </SideNavBar>
      </LeftSide>
      <Right>
        <VerticalLine />
        <Routes>
          <Route
            path="dashboard"
            element={<DashboardContainer ref={childDashboard} />}
          ></Route>
          <Route
            path="scheduler"
            element={<Scheduler ref={childDashboard} />}
          ></Route>
          <Route path="availability" element={<Availability />}></Route>
          <Route path="messages" element={<Messages />}></Route>
          <Route path="salaries" element={<Salaries />}></Route>
          <Route path="statistics" element={<Statistics />}></Route>
          <Route path="inOut" element={<InOut />}></Route>
          <Route
            path="*"
            element={<DashboardContainer ref={childDashboard} />}
          ></Route>
        </Routes>
      </Right>
    </Content>
  );
}

export default App;
