import {
  faChevronLeft,
  faChevronRight,
  faFolderClosed,
} from "@fortawesome/free-solid-svg-icons";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  ButtonIcon,
  CalendarHeader,
  FiguresStats,
  LabelFiguresStats,
  SchedulerHeaderButton,
  SchedulerHeaderStatsContainer,
} from "../../styledComponents";
export default function FullSchedulerSecondHeaderBar() {
  const figuresLabels = [
    { value: "Est. Wages", label: "Est. Wages" },
    { value: "O/T Cost", label: "O/T Cost" },
    { value: "Scheduled hours", label: "Scheduled hours" },
    { value: "O/T hours", label: "O/T hours" },
    { value: "Capacity utilized %", label: "Capacity utilized %" },
    { value: "Absense", label: "Absense" },
    { value: "Shifts", label: "Shifts" },
  ];

  const [alternatives, setAlternatives] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAlternatives(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <CalendarHeader evenly={true}>
      <SchedulerHeaderButton>
        <ButtonIcon icon={faChevronLeft} color={"#d6d6d6"}></ButtonIcon>
      </SchedulerHeaderButton>
      <SchedulerHeaderStatsContainer>
        <FiguresStats>0.00 CHF</FiguresStats>
        <LabelFiguresStats>Est. Wages</LabelFiguresStats>
      </SchedulerHeaderStatsContainer>
      <SchedulerHeaderStatsContainer>
        <FiguresStats>0.00 CHF</FiguresStats>
        <LabelFiguresStats>O/T Cost</LabelFiguresStats>
      </SchedulerHeaderStatsContainer>
      <SchedulerHeaderStatsContainer>
        <FiguresStats>312h 45min</FiguresStats>
        <LabelFiguresStats>Scheduled hours</LabelFiguresStats>
      </SchedulerHeaderStatsContainer>
      <SchedulerHeaderStatsContainer>
        <FiguresStats>24h 45min</FiguresStats>
        <LabelFiguresStats>O/T hours</LabelFiguresStats>
      </SchedulerHeaderStatsContainer>
      <SchedulerHeaderStatsContainer>
        <FiguresStats>0%</FiguresStats>
        <LabelFiguresStats>Capacity utilized %</LabelFiguresStats>
      </SchedulerHeaderStatsContainer>
      <SchedulerHeaderStatsContainer>
        <FiguresStats>0</FiguresStats>
        <LabelFiguresStats>Absense</LabelFiguresStats>
      </SchedulerHeaderStatsContainer>
      <SchedulerHeaderStatsContainer>
        <FiguresStats>45</FiguresStats>
        <LabelFiguresStats>Shifts</LabelFiguresStats>
      </SchedulerHeaderStatsContainer>
      <FormControl size="small" style={{ width: "70px", display: "flex" }}>
        <InputLabel>
          <ButtonIcon icon={faFolderClosed} color={"#d6d6d6"}></ButtonIcon>
        </InputLabel>
        <Select
          multiple
          value={alternatives}
          renderValue={() => null}
          onChange={handleChange}
          displayEmpty={true}
          MenuProps={{ disableScrollLock: true }}
        >
          {figuresLabels.map((element) => {
            return (
              <MenuItem value={element.value} key={element.value}>
                <ListItemIcon>
                  <Checkbox
                    checked={alternatives.indexOf(element.value) > -1}
                  />
                </ListItemIcon>
                <ListItemText primary={element.value}>
                  {element.value}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <SchedulerHeaderButton>
        <ButtonIcon icon={faChevronRight} color={"#d6d6d6"}></ButtonIcon>
      </SchedulerHeaderButton>
    </CalendarHeader>
  );
}
