/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStation = /* GraphQL */ `
  query GetStation($id: ID!) {
    getStation(id: $id) {
      stationName
      id
      color
      location
      hrName
      hrPhoto
      employeesNumber
      arrayOfPhotos
      employees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStations = /* GraphQL */ `
  query ListStations(
    $filter: ModelStationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        stationName
        id
        color
        location
        hrName
        hrPhoto
        employeesNumber
        arrayOfPhotos
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      employeeName
      photo
      role
      location
      email
      phone
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeName
        photo
        role
        location
        email
        phone
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShift = /* GraphQL */ `
  query GetShift($id: ID!) {
    getShift(id: $id) {
      date
      shiftStart
      shiftEnd
      employeeId
      stationId
      locationId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShifts = /* GraphQL */ `
  query ListShifts(
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        date
        shiftStart
        shiftEnd
        employeeId
        stationId
        locationId
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStationEmployee = /* GraphQL */ `
  query GetStationEmployee($id: ID!) {
    getStationEmployee(id: $id) {
      id
      stationID
      employeeID
      station {
        stationName
        id
        color
        location
        hrName
        hrPhoto
        employeesNumber
        arrayOfPhotos
        createdAt
        updatedAt
        __typename
      }
      employee {
        id
        employeeName
        photo
        role
        location
        email
        phone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStationEmployees = /* GraphQL */ `
  query ListStationEmployees(
    $filter: ModelStationEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStationEmployees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stationID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
