import React from "react";

const GlobalContext = React.createContext({
  monthIndex: 0,
  setMonthIndex: () => {},
  weekIndex: 0,
  setWeekIndex: () => {},
  smallCalendarMonth: 0,
  setSmallCalendarMonth: (index) => {},
  daySelected: "",
  setDaySelected: (day) => {},
  showSchedulerModal: false,
  setShowSchedulerModal: () => {},
  shifts: [{}],
  setShifts: () => {},
  employees: [{ stations: [] }],
  setEmployees: () => {},
  stations: [],
  setStations: () => {},
  selectedRole: [],
  setSelectedRole: () => {},
  selectedEmployee: [],
  setSelectedEmployee: () => {},
  dayInScheduleSelected: [],
  selectedLocation: [],
  setSelectedLocation: () => {},
  selectedStation: [],
  setSelectedStation: () => {},
  setDayInScheduleSelected: () => {},
  shiftForEdit: {},
  setShiftForEdit: () => {},
  selectedRange: "",
  setSelectedRange: () => {},
  currentWeek: [],
  setCurrentWeek: () => {},
});
export default GlobalContext;
