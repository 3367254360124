import { createTheme, ThemeProvider } from "@material-ui/core";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import {
  GridDateCheckbox,
  GridDateNumber,
  GridDay,
  GridDayNumberWrapper,
  GridHorizontalDuo,
  GridPhoto,
  ShiftStationLocation,
  ShiftStationName,
  ShiftTime,
  ShiftTotal,
  ShiftWrapper,
} from "../../styledComponents";
import { getLocationName, getStationName } from "../../utils";

export default function Day({ day, filteredShifts }) {
  const [dateEvents, setDateEvents] = useState([]);
  const {
    setDaySelected,
    employees,
    dayInScheduleSelected,
    setDayInScheduleSelected,
  } = useContext(GlobalContext);

  const findName = (e) => {
    let employee;

    employee = employees.find((x) => {
      return x.id.toString() === e.employeeId;
    });
    if (employee) {
      return employee.employeeName;
    }
  };
  const findPhoto = (e) => {
    let employee;

    employee = employees.find((x) => {
      return x.id.toString() === e.employeeId;
    });
    if (employee) {
      return employee.photo;
    }
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3068d9",
        "&$checked": {
          main: "#3068d9",
        },
      },
    },
  });

  const calculateShiftDuration = (start, stop) => {
    const stopHours = stop.slice(0, 2);
    const startHours = start.slice(0, 2);
    const hours = stopHours - startHours;
    const stopMinutes = stop.slice(3);
    const startMinutes = start.slice(3);
    const minutes = stopMinutes - startMinutes;
    const total = hours + "h." + minutes + "m";
    return total;
  };
  useEffect(() => {
    let events = filteredShifts.filter(
      (s) =>
        dayjs(day)
          .format("DD-MM-YY")
          .toString()
          .trim() === s.date
    );
    setDateEvents(events);
  }, [filteredShifts, day]);
  return (
    <GridDay
      onClick={() => {
        setDaySelected(day);
      }}
    >
      <GridDayNumberWrapper>
        <ThemeProvider theme={theme}>
          <GridDateCheckbox
            checked={dayInScheduleSelected.some(
              (d) => d.value === day.format("DD-MM-YY")
            )}
            color="primary"
            onClick={(e) => {
              e.target.checked
                ? setDayInScheduleSelected([
                    ...dayInScheduleSelected,
                    { value: day.format("DD-MM-YY") },
                  ])
                : setDayInScheduleSelected(
                    dayInScheduleSelected.filter(
                      (d) => d.value !== day.format("DD-MM-YY")
                    )
                  );
            }}
          ></GridDateCheckbox>
        </ThemeProvider>
        <GridDateNumber
          today={day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")}
        >
          {day.format("DD")}
        </GridDateNumber>
      </GridDayNumberWrapper>
      {dateEvents &&
        dateEvents.map((e) => (
          <ShiftWrapper stationId={e.stationId} key={e.id}>
            <ShiftTime>
              {e.shiftStart} - {e.shiftEnd}
              <ShiftTotal>
                {calculateShiftDuration(e.shiftStart, e.shiftEnd)}
              </ShiftTotal>
            </ShiftTime>{" "}
            <ShiftStationLocation>
              <ShiftStationName stationId={e.stationId}>
                {getStationName(e.stationId)}
              </ShiftStationName>
              , {getLocationName(e.locationId)}
            </ShiftStationLocation>
            <GridHorizontalDuo>
              <GridPhoto src={findPhoto(e)}></GridPhoto>
              {findName(e)}
            </GridHorizontalDuo>
          </ShiftWrapper>
        ))}
    </GridDay>
  );
}
