import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Button from "../controls/Button";
import { useForm, Form } from "./UseForm";
import Input from "../controls/Input";
import Select from "../controls/Select";
import { getLocationCollection, getStationCollection } from "../../utils";

const initialFValues = {
  id: 0,
  fullName: "",
  email: "",
  mobile: "",
  stationId: "",
  locationId: "",
};

export default function EmployeeAddForm(props) {
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("fullName" in fieldValues)
      temp.fullName = fieldValues.fullName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("stationId" in fieldValues)
      temp.stationId =
        fieldValues.stationId.length !== 0 ? "" : "This field is required.";
    if ("locationId" in fieldValues)
      temp.locationId =
        fieldValues.locationId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Input
            name="fullName"
            label="Full Name"
            value={values.fullName}
            onChange={handleInputChange}
            error={errors.fullName}
          />
          <Input
            label="Email"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
          />
          <Input
            label="Mobile"
            name="mobile"
            value={values.mobile}
            onChange={handleInputChange}
            error={errors.mobile}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            name="stationId"
            label="Station"
            value={values.stationId}
            onChange={handleInputChange}
            options={getStationCollection()}
            error={errors.stationId}
          />
          <Select
            name="locationId"
            label="Location"
            value={values.locationId}
            onChange={handleInputChange}
            options={getLocationCollection()}
            error={errors.locationId}
          />

          <div>
            <Button type="submit" text="Invite user" />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
