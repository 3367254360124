import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import {
  ButtonIcon,
  SchedulerHeaderButton,
  SmallCalendarButton,
  SmallCalendarCapitalDay,
  SmallCalendarHeader,
  SmallGridMonth,
} from "../../styledComponents";
import { getMonth } from "../../utils";

export default function SmallCalendar() {
  const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, setSmallCalendarMonth, daySelected, setDaySelected } =
    useContext(GlobalContext);
  useEffect(() => {
    setCurrentMonthIdx(monthIndex);
  }, [monthIndex]);

  const handlePrevMonth = () => {
    setCurrentMonthIdx(currentMonthIdx - 1);
  };
  const handleNextMonth = () => {
    setCurrentMonthIdx(currentMonthIdx + 1);
  };

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIdx));
  }, [currentMonthIdx]);

  return (
    <>
      <SmallCalendarHeader>
        {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM YYYY")}
        <SchedulerHeaderButton onClick={handlePrevMonth}>
          <ButtonIcon icon={faChevronLeft}></ButtonIcon>
        </SchedulerHeaderButton>
        <SchedulerHeaderButton onClick={handleNextMonth}>
          <ButtonIcon icon={faChevronRight}></ButtonIcon>
        </SchedulerHeaderButton>
      </SmallCalendarHeader>
      <SmallGridMonth month={true}>
        {currentMonth[0].map((day, i) => (
          <SmallCalendarCapitalDay key={i}>
            {day.format("dd").charAt(0)}
          </SmallCalendarCapitalDay>
        ))}
        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <SmallCalendarButton
                onClick={(e) => {
                  e.preventDefault();
                  setSmallCalendarMonth(currentMonthIdx);
                  setDaySelected(day);
                }}
                key={idx}
                today={day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")}
                selected={daySelected === day}
              >
                {day.format("D")}
              </SmallCalendarButton>
            ))}
          </React.Fragment>
        ))}
      </SmallGridMonth>
    </>
  );
}
