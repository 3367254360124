import {
  faChartSimple,
  faChevronLeft,
  faChevronRight,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import dayjs from "dayjs";
import React, { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import {
  ButtonIcon,
  CalendarHeader,
  PDFAndBarsWrapper,
  SchedulerHeaderButton,
} from "../../styledComponents";
export default function FullSchedulerHeaderBar() {
  const range = ["Month", "Week"];
  const {
    monthIndex,
    setMonthIndex,
    weekIndex,
    setWeekIndex,
    selectedRange,
    setSelectedRange,
  } = useContext(GlobalContext);
  let nextSixMonths = [];
  let nextSixWeeks = [];

  const handlePrev = () => {
    selectedRange === "Month"
      ? setMonthIndex(monthIndex - 1)
      : setWeekIndex(weekIndex - 1);
  };
  const handleNext = () => {
    selectedRange === "Month"
      ? setMonthIndex(monthIndex + 1)
      : setWeekIndex(weekIndex + 1);
  };
  const handleReset = () => {
    selectedRange === "Month"
      ? setMonthIndex(
          monthIndex === dayjs().month()
            ? monthIndex + Math.random()
            : dayjs().month()
        )
      : setWeekIndex(
          weekIndex === dayjs().week() - 1
            ? weekIndex + Math.random()
            : dayjs().week() - 1
        );
  };

  const onChangeMonth = (e) => {
    setMonthIndex(e.target.value);
  };
  const onChangeWeek = (e) => {
    setWeekIndex(e.target.value);
  };
  const changeRange = (e) => {
    setSelectedRange(e.target.value);
  };

  const generateNextSixMonths = () => {
    nextSixMonths.push({
      value: dayjs().month(),
      label: "Current",
    });
    for (let i = 0; i < 6; i++) {
      nextSixMonths.push({
        value: monthIndex + i,
        label: dayjs(new Date(dayjs().year(), monthIndex + i)).format(
          "MMM YYYY"
        ),
      });
    }
  };
  const generateNextSixWeeks = () => {
    nextSixWeeks.push({
      value: dayjs().week() - 1,
      label: "Current",
    });

    for (let i = 0; i < 6; i++) {
      nextSixWeeks.push({
        value: weekIndex + i,
        label: weekIndex + i,
      });
    }
  };
  generateNextSixMonths();
  generateNextSixWeeks();
  return (
    <CalendarHeader color={"#f9f9f9"}>
      <SchedulerHeaderButton onClick={handlePrev}>
        <ButtonIcon icon={faChevronLeft} color={"#d6d6d6"}></ButtonIcon>
      </SchedulerHeaderButton>
      <SchedulerHeaderButton onClick={handleNext}>
        <ButtonIcon icon={faChevronRight} color={"#d6d6d6"}></ButtonIcon>
      </SchedulerHeaderButton>
      <SchedulerHeaderButton width={"70px"} onClick={handleReset}>
        Today
      </SchedulerHeaderButton>

      <FormControl
        variant="outlined"
        size="small"
        style={{
          width: "120px",
          display: "flex",
          marginLeft: "20px",
        }}
      >
        <InputLabel>Select range</InputLabel>
        <Select
          value={selectedRange}
          onChange={changeRange}
          autoWidth
          label="Select range"
          style={{ height: "38px" }}
          MenuProps={{
            disableScrollLock: true,
            getContentAnchorEl: () => null,
          }}
        >
          {range.map((element, index) => {
            return (
              <MenuItem value={element} key={element + index}>
                <ListItemText primary={element}></ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {selectedRange === "Month" ? (
        <FormControl
          variant="outlined"
          size="small"
          style={{
            width: "120px",
            display: "flex",
            marginLeft: "20px",
          }}
        >
          <InputLabel>Select month</InputLabel>
          <Select
            value={monthIndex}
            onChange={onChangeMonth}
            autoWidth
            label="Select month"
            style={{ height: "38px" }}
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: () => null,
            }}
          >
            {nextSixMonths.map((element, index) => {
              return (
                <MenuItem value={element.value} key={element.value + index}>
                  <ListItemText primary={element.label}></ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl
          variant="outlined"
          size="small"
          style={{
            width: "120px",
            display: "flex",
            marginLeft: "20px",
          }}
        >
          <InputLabel>Select week</InputLabel>
          <Select
            value={weekIndex}
            onChange={onChangeWeek}
            autoWidth
            label="Select week"
            style={{ height: "38px" }}
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: () => null,
            }}
          >
            {nextSixWeeks.map((element, index) => {
              return (
                <MenuItem value={element.value} key={element.value + index}>
                  <ListItemText primary={element.label}></ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}

      <PDFAndBarsWrapper>
        <SchedulerHeaderButton width={"200px"}>
          <ButtonIcon icon={faPrint} color={"#d6d6d6"}></ButtonIcon>
          Schedule to PDF
        </SchedulerHeaderButton>
        <SchedulerHeaderButton width={"50px"}>
          <ButtonIcon icon={faChartSimple} color={"#3068D9"}></ButtonIcon>
        </SchedulerHeaderButton>
      </PDFAndBarsWrapper>
    </CalendarHeader>
  );
}
