import React from "react";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import {
  NotificationsList,
  NotificationsListItem,
  NotificationsListIcon,
  Notifications,
  ListPhoto,
  BoldText,
  RegularText,
  DigitalClock,
  Yesterday,
} from "../../styledComponents.js";

export function Chat({
  carlPhoto,
  dummyMessage,
  creator,
  exampleTime,
  yesterday,
}) {
  const chatItems = [
    {
      primaryPhoto: carlPhoto,
      message: dummyMessage,
      creator: creator,
    },
    {
      primaryPhoto: carlPhoto,
      message: dummyMessage,
      creator: creator,
    },
    {
      primaryPhoto: carlPhoto,
      message: dummyMessage,
      creator: creator,
    },
    {
      primaryPhoto: carlPhoto,
      message: dummyMessage,
      creator: creator,
    },
    {
      primaryPhoto: carlPhoto,
      message: dummyMessage,
      creator: creator,
    },
  ];
  const notificationsListItemsColors = ["#f9f9f9", "#edf0ff", "#fff1e9"];

  const renderChatList = (item, i) => {
    return (
      <>
        <NotificationsListItem
          color={notificationsListItemsColors[i % 3]}
          key={i}
        >
          <NotificationsListIcon icon={faMessage} />
          <Notifications>
            <ListPhoto src={item.primaryPhoto} />
            <BoldText>{item.creator}</BoldText>
            <RegularText>{item.message}</RegularText>
            <DigitalClock>{exampleTime}</DigitalClock>
          </Notifications>
        </NotificationsListItem>

        {i === 2 && <Yesterday>{yesterday}</Yesterday>}
      </>
    );
  };

  return (
    <NotificationsList>
      {chatItems.map((item, i) => {
        return renderChatList(item, i);
      })}
    </NotificationsList>
  );
}
