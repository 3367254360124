import React, { forwardRef, useImperativeHandle, useState } from "react";

import Home from "./Home";
import {
  Content,
  Top,
  Navbar,
  NavbarHeader,
  NavbarLinksContainer,
  NavbarLinkInnerContainer,
  NavbarLink,
  Count,
  NotificationsContainer,
  Circle,
  IconNotifications,
  RedCircle,
  Number,
  ProfileImage,
  NotificationIconAndProfileContainer,
} from "../../styledComponents.js";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Stations } from "./Stations";
import { Locations } from "./Locations";
import { Employees } from "./Employees";

const DashboardContainer = forwardRef((props, ref) => {
  const navbarLinkDashboard = "Dashboard",
    navbarLinkHome = "Home",
    employees = "Employees",
    employeesCount = "245",
    stations = "Stations",
    stationsCount = "15",
    location = "Locations",
    locationCount = "3",
    profileImage =
      "https://anima-uploads.s3.amazonaws.com/projects/62f4d31bf593149d8b2ce389/releases/62f4e504f3486458d6da122f/img/ellipse-31@2x.png",
    notificationsNavBarRedCircleNumber = "15";
  const [navActive, setNavActive] = useState(navbarLinkHome);
  const [showNotificationsNumber, setShowNotificationsNumber] = useState(true);

  const clearNotificationsNumberFromChild = () => {
    setShowNotificationsNumber(false);
  };

  useImperativeHandle(ref, () => ({
    setNavActiveFromParent() {
      setNavActive(navbarLinkHome);
    },
  }));

  const renderActiveTab = () => {
    switch (navActive) {
      case "Home":
        return (
          <Home
            clearNotificationsNumberFromChild={
              clearNotificationsNumberFromChild
            }
            showNotificationsNumber={showNotificationsNumber}
          ></Home>
        );
      case "Employees":
        return <Employees></Employees>;
      case "Stations":
        return <Stations></Stations>;
      case "Locations":
        return <Locations></Locations>;
      default:
        break;
    }
  };
  return (
    <Content>
      <Top>
        <Navbar>
          <NavbarHeader
            onClick={() => {
              setNavActive(navbarLinkHome);
            }}
          >
            {navbarLinkDashboard}
          </NavbarHeader>
          <NavbarLinksContainer>
            <NavbarLink
              onClick={() => {
                setNavActive(navbarLinkHome);
              }}
              active={navActive === navbarLinkHome}
            >
              {navbarLinkHome}
            </NavbarLink>
            <NavbarLinkInnerContainer>
              <NavbarLink
                onClick={() => {
                  setNavActive(employees);
                }}
                active={navActive === employees}
              >
                {employees}
              </NavbarLink>
              <Count>{employeesCount}</Count>
            </NavbarLinkInnerContainer>
            <NavbarLink>
              <NavbarLinkInnerContainer>
                <NavbarLink
                  onClick={() => {
                    setNavActive(stations);
                  }}
                  active={navActive === stations}
                >
                  {stations}
                </NavbarLink>
                <Count>{stationsCount}</Count>
              </NavbarLinkInnerContainer>
            </NavbarLink>
            <NavbarLink>
              <NavbarLinkInnerContainer>
                <NavbarLink
                  onClick={() => {
                    setNavActive(location);
                  }}
                  active={navActive === location}
                >
                  {location}
                </NavbarLink>
                <Count>{locationCount}</Count>
              </NavbarLinkInnerContainer>
            </NavbarLink>
          </NavbarLinksContainer>
          <NotificationIconAndProfileContainer>
            <NotificationsContainer>
              <Circle></Circle>
              <IconNotifications icon={faBell} />
              <RedCircle show={showNotificationsNumber}>
                <Number show={showNotificationsNumber}>
                  {notificationsNavBarRedCircleNumber}
                </Number>
              </RedCircle>
            </NotificationsContainer>
            <ProfileImage src={profileImage} />
          </NotificationIconAndProfileContainer>
        </Navbar>
        {renderActiveTab()}
      </Top>
    </Content>
  );
});
export default DashboardContainer;
