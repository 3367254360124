import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import {
  GridDay,
  ShiftStationLocation,
  ShiftStationName,
  ShiftTime,
  ShiftTotal,
  ShiftWrapper,
} from "../../styledComponents";
import { getLocationName, getStationName } from "../../utils";

export default function WeekDay({ day, filteredShifts, employeeId, message }) {
  const [dateEvents, setDateEvents] = useState([]);
  const { setDaySelected } = useContext(GlobalContext);

  const calculateShiftDuration = (start, stop) => {
    const stopHours = stop.slice(0, 2);
    const startHours = start.slice(0, 2);
    const hours = stopHours - startHours;
    const stopMinutes = stop.slice(3);
    const startMinutes = start.slice(3);
    const minutes = stopMinutes - startMinutes;
    const total = hours + "h." + minutes + "m";
    return total;
  };
  useEffect(() => {
    let events = filteredShifts.filter(
      (s) => dayjs(day).format("DD-MM-YY").toString().trim() === s.date
    );
    setDateEvents(events);
  }, [filteredShifts, day]);
  return (
    <GridDay
      onClick={() => {
        setDaySelected(day);
      }}
    >
      {dateEvents.length > 0 &&
        dateEvents.map((e) => {
          return (
            e.employeeId === employeeId.toString() && (
              <ShiftWrapper stationId={e.stationId} key={e.id}>
                <ShiftTime>
                  {e.shiftStart} - {e.shiftEnd}
                  <ShiftTotal>
                    {calculateShiftDuration(e.shiftStart, e.shiftEnd)}
                  </ShiftTotal>
                </ShiftTime>{" "}
                <ShiftStationLocation>
                  <ShiftStationName stationId={e.stationId}>
                    {getStationName(e.stationId)}
                  </ShiftStationName>
                  , {getLocationName(e.locationId)}
                </ShiftStationLocation>
              </ShiftWrapper>
            )
          );
        })}
    </GridDay>
  );
}
