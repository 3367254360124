import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import { GridMonth, GridWeekdayNames } from "../../styledComponents";
import { getLocationName, getStationName } from "../../utils";
import Day from "./Day";

export default function Month({ month }) {
  const [filteredShifts, setFilteredShifts] = useState([]);
  const {
    shifts,
    employees,
    selectedEmployee,
    selectedLocation,
    selectedStation,
  } = useContext(GlobalContext);

  useEffect(() => {
    const filterShiftsByEmployee = (shift) => {
      let employee = employees.filter((e) => shift.employeeId === e.id);
      if (employee.length > 0) {
        return selectedEmployee.some((e) => employee[0].employeeName === e);
      } else {
        return false;
      }
    };
    const filterShiftsByLocation = (shift) => {
      return selectedLocation.some(
        (e) => getLocationName(shift.locationId) === e
      );
    };
    const filterShiftsByStation = (shift) => {
      return selectedStation.some((e) => getStationName(shift.stationId) === e);
    };
    let tempFilteredShifts = [];
    if (shifts.length > 0) {
      tempFilteredShifts = shifts;
    }
    if (selectedEmployee.length > 0) {
      tempFilteredShifts = tempFilteredShifts.filter((shift) =>
        filterShiftsByEmployee(shift)
      );
    }
    if (selectedLocation.length > 0) {
      tempFilteredShifts = tempFilteredShifts.filter((shift) =>
        filterShiftsByLocation(shift)
      );
    }
    if (selectedStation.length > 0) {
      tempFilteredShifts = tempFilteredShifts.filter((shift) =>
        filterShiftsByStation(shift)
      );
    }
    setFilteredShifts(tempFilteredShifts);
  }, [shifts, selectedEmployee, selectedLocation, selectedStation, employees]);
  return (
    <>
      <GridWeekdayNames month={true}>
        {month[0].map((day) => (
          <p key={day}>{day.format("ddd")}</p>
        ))}
      </GridWeekdayNames>

      <GridMonth month={true}>
        {month.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, idx) => (
              <Day
                day={day}
                key={idx}
                rowIdx={i}
                filteredShifts={filteredShifts}
              ></Day>
            ))}
          </React.Fragment>
        ))}
      </GridMonth>
    </>
  );
}
