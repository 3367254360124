import React, { useState, useContext } from "react";
import Modal from "./EmployeesModal";
import {
  faSquarePlus,
  faFileContract,
  faTrash,
  faClose,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { API } from "aws-amplify";
import { listStations } from "../../graphql/queries";
import {
  createEmployee as createEmployeeMutation,
  createStationEmployee as createStationEmployeeMutation,
} from "../../graphql/mutations";
import {
  Contract,
  ContractAndSearch,
  GridTextBiggerBlue,
  GridTextSmaller,
  GridCheckbox,
  EmployeesGridArea,
  GridHeader,
  GridVerticalDuo,
  GridPhoto,
  EmployeesStationsButton,
  GridHorizontalDuo,
  FilterAndSearchContainer,
  FilterSection,
  GridScroller,
  SearchForm,
  SearchInput,
  StyledPlusIcon,
  Grid,
  ButtonIcon,
  GridButton,
  DeleteAllAndEdit,
  GridEditAndDelete,
  ClearButton,
} from "../../styledComponents.js";
import EmployeeAddForm from "./EmployeesAddForm";
import GlobalContext from "../../context/GlobalContext";
import { listEmployeesWithList } from "../../graphql/custom-queries";
import {
  getLocationCollection,
  getLocationName,
  getRoleCollection,
  getStationName,
} from "../../utils";
import {
  Checkbox,
  createTheme,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
} from "@material-ui/core";
export const Employees = () => {
  const [employeeSelectedCount, setEmployeeSelectedCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const {
    employees,
    setEmployees,
    selectedLocation,
    setSelectedLocation,
    stations,
    selectedStation,
    setSelectedStation,
    selectedRole,
    setSelectedRole,
  } = useContext(GlobalContext);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3068d9",
        "&$checked": {
          main: "#3068d9",
        },
      },
    },
  });
  const checkTheFilters = (employee) => {
    let pass = true;
    if (selectedLocation.length > 0) {
      pass = selectedLocation.some((l) => l === employee.location);
      if (!pass) {
        return pass;
      }
    }
    if (selectedStation.length > 0) {
      pass = selectedStation.some((s) =>
        employee.stations.items.some((e) => s === e.station.stationName)
      );
      if (!pass) {
        return pass;
      }
    }
    if (selectedRole.length > 0) {
      pass = selectedRole.some((r) => r === employee.role);
    }
    return pass;
  };
  const clearCheckBoxes = () => {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
    setEmployeeSelectedCount(0);
  };
  const isCheckboxSelected = (i) => {
    let isChecked;

    const checkboxArray = Array.from(
      document.querySelectorAll("input[type=checkbox].employees")
    );

    if (checkboxArray.length > 0) {
      if (checkboxArray[i]) {
        isChecked = checkboxArray[i].checked === true;
      }
    }
    return isChecked;
  };

  const fetchEmployees = async () => {
    try {
      const apiData = await API.graphql({ query: listEmployeesWithList });
      const employeesFromAPI = apiData.data.listEmployees.items;

      setEmployees(employeesFromAPI);
    } catch (error) {}
  };

  const handleChangeLocation = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedLocation([]);
      return;
    }
    setSelectedLocation(event.target.value);
  };

  const handleChangeStation = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedStation([]);
      return;
    }
    setSelectedStation(event.target.value);
  };
  const handleChangeRole = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedRole([]);
      return;
    }
    setSelectedRole(event.target.value);
  };
  const addOrEdit = async (values) => {
    let stationApiData = await API.graphql({ query: listStations });
    let stationsFromApi = stationApiData.data.listStations.items;

    let station = stationsFromApi.filter(
      (s) => s.stationName === getStationName(values.stationId)
    );

    const data = {
      employeeName: values.fullName,
      email: values.email,
      phone: values.mobile,
      photo:
        "https://anima-uploads.s3.amazonaws.com/projects/62f4d31bf593149d8b2ce389/releases/62f4e504f3486458d6da122f/img/ellipse-7-8@2x.png",
      location: getLocationName(values.locationId),
      role: "Employee",
    };
    let response = await API.graphql({
      query: createEmployeeMutation,
      variables: { input: data },
    });
    const stationData = {
      stationID: station[0].id,
      employeeID: response.data.createEmployee.id,
    };
    await API.graphql({
      query: createStationEmployeeMutation,
      variables: { input: stationData },
    });
    fetchEmployees();
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        <EmployeeAddForm addOrEdit={addOrEdit}></EmployeeAddForm>
      </Modal>
      <FilterAndSearchContainer>
        <StyledPlusIcon
          icon={faSquarePlus}
          onClick={() => setShowModal(true)}
        ></StyledPlusIcon>
        <FilterSection width={"500px"}>
          <FormControl
            variant="outlined"
            size="small"
            style={{
              width: "120px",
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <InputLabel>Locations</InputLabel>
            <Select
              multiple
              value={selectedLocation}
              onChange={handleChangeLocation}
              MenuProps={{
                disableScrollLock: true,
                getContentAnchorEl: () => null,
              }}
              autoWidth
              renderValue={(selected) => selected.join(", ")}
              label="Locations"
              style={{ height: "38px" }}
            >
              <MenuItem value="None" key="location_none">
                <ListItemText primary="None"></ListItemText>
              </MenuItem>
              {getLocationCollection().map((element) => {
                return (
                  <MenuItem value={element.value} key={element.id}>
                    <ListItemIcon>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          color="primary"
                          checked={selectedLocation.indexOf(element.value) > -1}
                        />
                      </ThemeProvider>
                    </ListItemIcon>

                    <ListItemText primary={element.value}></ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            style={{
              width: "120px",
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <InputLabel>Stations</InputLabel>
            <Select
              multiple
              value={selectedStation}
              onChange={handleChangeStation}
              MenuProps={{
                disableScrollLock: true,
                getContentAnchorEl: () => null,
              }}
              autoWidth
              renderValue={(selected) => selected.join(", ")}
              label="Stations"
              style={{ height: "38px" }}
            >
              <MenuItem value="None" key="station_none">
                <ListItemText primary="None"></ListItemText>
              </MenuItem>
              {stations.map((element) => {
                return (
                  <MenuItem value={element.stationName} key={element.id}>
                    <ListItemIcon>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          color="primary"
                          checked={
                            selectedStation.indexOf(element.stationName) > -1
                          }
                        />
                      </ThemeProvider>
                    </ListItemIcon>

                    <ListItemText primary={element.stationName}></ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            style={{
              width: "120px",
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedRole}
              onChange={handleChangeRole}
              MenuProps={{
                disableScrollLock: true,
                getContentAnchorEl: () => null,
              }}
              autoWidth
              renderValue={(selected) => selected.join(", ")}
              label="Roles"
              style={{ height: "38px" }}
            >
              <MenuItem value="None" key="role_none">
                <ListItemText primary="None"></ListItemText>
              </MenuItem>
              {getRoleCollection().map((element) => {
                return (
                  <MenuItem value={element.value} key={element.id}>
                    <ListItemIcon>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          color="primary"
                          checked={selectedRole.indexOf(element.value) > -1}
                        />
                      </ThemeProvider>
                    </ListItemIcon>

                    <ListItemText primary={element.value}></ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </FilterSection>
        {employeeSelectedCount > 0 && (
          <DeleteAllAndEdit>
            <GridButton>
              <ButtonIcon color={"#F94C53"} icon={faTrash}></ButtonIcon>
            </GridButton>
            <ClearButton onClick={clearCheckBoxes}>
              <ButtonIcon icon={faClose}></ButtonIcon>
              Clear
            </ClearButton>
          </DeleteAllAndEdit>
        )}
        <ContractAndSearch>
          <Contract>
            <ButtonIcon icon={faFileContract}></ButtonIcon>
            Contract
          </Contract>
          <SearchForm>
            <SearchInput
              type="text"
              placeholder="Search for employees"
            ></SearchInput>
          </SearchForm>
        </ContractAndSearch>
      </FilterAndSearchContainer>

      <GridScroller>
        <Grid tab="employees">
          <GridHeader></GridHeader>
          <GridHeader></GridHeader>
          <GridHeader>Name</GridHeader>
          <GridHeader>Employee id</GridHeader>
          <GridHeader>Stations</GridHeader>
          <GridHeader>Locations</GridHeader>
          <GridHeader>Email</GridHeader>
          <GridHeader>Phone</GridHeader>
          <GridHeader></GridHeader>

          {employees.map(function(item, i) {
            if (checkTheFilters(item))
              return (
                <React.Fragment key={i}>
                  <GridCheckbox
                    className="employees"
                    type="checkbox"
                    key={i}
                    onClick={(e) => {
                      e.target.checked
                        ? setEmployeeSelectedCount(employeeSelectedCount + 1)
                        : setEmployeeSelectedCount(employeeSelectedCount - 1);
                    }}
                  />
                  <GridPhoto src={item.photo} />
                  <GridVerticalDuo>
                    <GridTextBiggerBlue>{item.employeeName}</GridTextBiggerBlue>
                    <GridTextSmaller>{item.role}</GridTextSmaller>
                  </GridVerticalDuo>
                  <EmployeesGridArea name="id">
                    {item.id.slice(0, 7)}...
                  </EmployeesGridArea>

                  <GridHorizontalDuo>
                    {item.stations.items &&
                      item.stations.items.map((station, j) => {
                        return (
                          <EmployeesStationsButton
                            station={station.station.stationName}
                            key={j}
                          >
                            {station.station.stationName}
                          </EmployeesStationsButton>
                        );
                      })}
                  </GridHorizontalDuo>

                  <EmployeesGridArea name="locations">
                    {item.location}
                  </EmployeesGridArea>
                  <EmployeesGridArea name="email">
                    {item.email}
                  </EmployeesGridArea>
                  <EmployeesGridArea name="photo">
                    {item.phone}
                  </EmployeesGridArea>
                  <GridEditAndDelete isCheckboxSelected={isCheckboxSelected(i)}>
                    <GridButton>
                      <ButtonIcon color={"#2576e4"} icon={faEdit}></ButtonIcon>
                    </GridButton>
                    <GridButton>
                      <ButtonIcon icon={faTrash} color={"#F94C53"}></ButtonIcon>
                    </GridButton>
                  </GridEditAndDelete>
                </React.Fragment>
              );
            else return <React.Fragment key={i}></React.Fragment>;
          })}
        </Grid>
      </GridScroller>
    </React.Fragment>
  );
};
