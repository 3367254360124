export const listEmployeesWithList = /* GraphQL */ `
  query ListEmployeesWithList(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeName
        photo
        role
        location
        email
        phone
        stations {
          items {
            station {
              stationName
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listStationsWithList = /* GraphQL */ `
  query listStationsWithList(
    $filter: ModelStationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        stationName
        id
        color
        location
        hrName
        hrPhoto
        employeesNumber
        arrayOfPhotos
        employees {
          items {
            employee {
              employeeName
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;