import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import {
  BoldText,
  ButtonIcon,
  GridHorizontalDuo,
  GridMonth,
  GridPhoto,
  GridVerticalDuo,
  GridWeekdayNames,
  GridWeekVerticalWrapper,
} from "../../styledComponents";
import { getLocationName, getStationName } from "../../utils";
import WeekDay from "./WeekDay";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
export default function Week() {
  const [filteredShifts, setFilteredShifts] = useState([]);
  const {
    shifts,
    employees,
    selectedEmployee,
    selectedLocation,
    selectedStation,
    currentWeek,
  } = useContext(GlobalContext);

  const projectedSales = [
    "ProjectedSales",
    "24.00 CHF",
    "15.00 CHF",
    "37.47 CHF",
    "157.75 CHF",
    "24.00 CHF",
    "24.00 CHF",
    "0.00 CHF",
  ];
  const totalShifts = ["Total shifts", "3", "3", "3", "1", "2", "1", "0"];
  useEffect(() => {
    const filterShiftsByEmployee = (shift) => {
      let employee = employees.filter((e) => shift.employeeId === e.id);
      if (employee.length > 0) {
        return selectedEmployee.some((e) => employee[0].name === e);
      } else {
        return false;
      }
    };
    const filterShiftsByLocation = (shift) => {
      return selectedLocation.some(
        (e) => getLocationName(shift.locationId) === e
      );
    };
    const filterShiftsByStation = (shift) => {
      return selectedStation.some((e) => getStationName(shift.stationId) === e);
    };
    let tempFilteredShifts = [];
    if (shifts.length > 0) {
      tempFilteredShifts = shifts;
    }
    if (selectedEmployee.length > 0) {
      tempFilteredShifts = tempFilteredShifts.filter((shift) =>
        filterShiftsByEmployee(shift)
      );
    }
    if (selectedLocation.length > 0) {
      tempFilteredShifts = tempFilteredShifts.filter((shift) =>
        filterShiftsByLocation(shift)
      );
    }
    if (selectedStation.length > 0) {
      tempFilteredShifts = tempFilteredShifts.filter((shift) =>
        filterShiftsByStation(shift)
      );
    }
    setFilteredShifts(tempFilteredShifts);
  }, [
    shifts,
    selectedEmployee,
    selectedLocation,
    selectedStation,
    currentWeek,
    employees,
  ]);
  return (
    <>
      <GridWeekdayNames>
        <p key={"blank"}></p>
        {currentWeek.map((day) => (
          <p key={day}>{day.format("ddd DD")}</p>
        ))}
      </GridWeekdayNames>

      <GridMonth>
        <>
          <GridHorizontalDuo key={"unAssigned"}>
            <ButtonIcon
              icon={faUserPlus}
              margin={"10px"}
              color={"#3068d9"}
            ></ButtonIcon>
            Unassigned
          </GridHorizontalDuo>
          {currentWeek.map((day, i) => {
            return (
              <WeekDay
                day={day}
                key={i}
                filteredShifts={filteredShifts}
                employeeId="0"
              ></WeekDay>
            );
          })}
        </>
        {employees.map((employee) => {
          return (
            <React.Fragment key={employee.id}>
              <GridHorizontalDuo key={employee.photo}>
                <GridPhoto src={employee.photo}></GridPhoto>
                <GridWeekVerticalWrapper>
                  <BoldText>{employee.name}</BoldText>
                  <BoldText>30 h. 40 min</BoldText>
                </GridWeekVerticalWrapper>
              </GridHorizontalDuo>
              {currentWeek.map((day, i) => (
                <WeekDay
                  day={day}
                  key={employee.id + day}
                  filteredShifts={filteredShifts}
                  employeeId={employee.id}
                ></WeekDay>
              ))}
            </React.Fragment>
          );
        })}
        {projectedSales.map((ps, index) => (
          <GridVerticalDuo key={ps + index}>
            <div>{ps}</div>
            <div>{totalShifts[index]}</div>
          </GridVerticalDuo>
        ))}
      </GridMonth>
    </>
  );
}
