import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ContextWrapper from "./context/ContextWrapper";
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ContextWrapper>
      <App />
    </ContextWrapper>
  </BrowserRouter >
);


