/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStation = /* GraphQL */ `
  mutation CreateStation(
    $input: CreateStationInput!
    $condition: ModelStationConditionInput
  ) {
    createStation(input: $input, condition: $condition) {
      stationName
      id
      color
      location
      hrName
      hrPhoto
      employeesNumber
      arrayOfPhotos
      employees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStation = /* GraphQL */ `
  mutation UpdateStation(
    $input: UpdateStationInput!
    $condition: ModelStationConditionInput
  ) {
    updateStation(input: $input, condition: $condition) {
      stationName
      id
      color
      location
      hrName
      hrPhoto
      employeesNumber
      arrayOfPhotos
      employees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStation = /* GraphQL */ `
  mutation DeleteStation(
    $input: DeleteStationInput!
    $condition: ModelStationConditionInput
  ) {
    deleteStation(input: $input, condition: $condition) {
      stationName
      id
      color
      location
      hrName
      hrPhoto
      employeesNumber
      arrayOfPhotos
      employees {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      employeeName
      photo
      role
      location
      email
      phone
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      employeeName
      photo
      role
      location
      email
      phone
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      employeeName
      photo
      role
      location
      email
      phone
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShift = /* GraphQL */ `
  mutation CreateShift(
    $input: CreateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    createShift(input: $input, condition: $condition) {
      date
      shiftStart
      shiftEnd
      employeeId
      stationId
      locationId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      date
      shiftStart
      shiftEnd
      employeeId
      stationId
      locationId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShift = /* GraphQL */ `
  mutation DeleteShift(
    $input: DeleteShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    deleteShift(input: $input, condition: $condition) {
      date
      shiftStart
      shiftEnd
      employeeId
      stationId
      locationId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStationEmployee = /* GraphQL */ `
  mutation CreateStationEmployee(
    $input: CreateStationEmployeeInput!
    $condition: ModelStationEmployeeConditionInput
  ) {
    createStationEmployee(input: $input, condition: $condition) {
      id
      stationID
      employeeID
      station {
        stationName
        id
        color
        location
        hrName
        hrPhoto
        employeesNumber
        arrayOfPhotos
        createdAt
        updatedAt
        __typename
      }
      employee {
        id
        employeeName
        photo
        role
        location
        email
        phone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStationEmployee = /* GraphQL */ `
  mutation UpdateStationEmployee(
    $input: UpdateStationEmployeeInput!
    $condition: ModelStationEmployeeConditionInput
  ) {
    updateStationEmployee(input: $input, condition: $condition) {
      id
      stationID
      employeeID
      station {
        stationName
        id
        color
        location
        hrName
        hrPhoto
        employeesNumber
        arrayOfPhotos
        createdAt
        updatedAt
        __typename
      }
      employee {
        id
        employeeName
        photo
        role
        location
        email
        phone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStationEmployee = /* GraphQL */ `
  mutation DeleteStationEmployee(
    $input: DeleteStationEmployeeInput!
    $condition: ModelStationEmployeeConditionInput
  ) {
    deleteStationEmployee(input: $input, condition: $condition) {
      id
      stationID
      employeeID
      station {
        stationName
        id
        color
        location
        hrName
        hrPhoto
        employeesNumber
        arrayOfPhotos
        createdAt
        updatedAt
        __typename
      }
      employee {
        id
        employeeName
        photo
        role
        location
        email
        phone
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
