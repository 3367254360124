import React, { useState, useEffect } from "react";
import {
  faSquarePlus,
  faEdit,
  faTrash,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

import {
  ContractAndSearch,
  GridTextBiggerBlue,
  GridCheckbox,
  GridHeader,
  FilterAndSearchContainer,
  FilterSection,
  Grid,
  GridScroller,
  SearchForm,
  SearchInput,
  SelectField,
  StationsEmployeesPhoto,
  StationsExtraEmployees,
  StyledPlusIcon,
  GridHorizontalPhotos,
  GridEditAndDelete,
  GridButton,
  ButtonIcon,
  DeleteAllAndEdit,
  ClearButton,
  EmployeesGridArea,
} from "../../styledComponents.js";
export const Locations = () => {
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedTimeZone, setSelectedTimeZone] = useState();
  const [locationsSelectedCount, setLocationsSelectedCount] = useState(0);

  const [locations, setLocations] = useState([]);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  const generateLocationsForFiltering = (response) => {
    const tempLocationsArray = [];
    response.forEach((location) => {
      tempLocationsArray.push(location.name);
    });
    const uniqeLocations = [...new Set(tempLocationsArray)];
    const finalLocationsArray = [];
    uniqeLocations.forEach((location) => {
      finalLocationsArray.push({
        value: location.toLowerCase(),
        label: location,
      });
    });
    setUniqueLocations(finalLocationsArray);
  };

  const generateCountriesForFiltering = (response) => {
    const tempCountriesArray = [];
    response.forEach((location) => {
      tempCountriesArray.push(location.country);
    });
    const uniqeCountries = [...new Set(tempCountriesArray)];
    const finalCountriesArray = [];
    uniqeCountries.forEach((country) => {
      finalCountriesArray.push({
        value: country.toLowerCase(),
        label: country,
      });
    });
    setCountries(finalCountriesArray);
  };

  const generateTimezonesForFiltering = (response) => {
    const tempTimeZonesArray = [];
    response.forEach((location) => {
      tempTimeZonesArray.push(location.timeZone);
    });
    const uniqeTimeZones = [...new Set(tempTimeZonesArray)];
    const finalTimeZonesArray = [];
    uniqeTimeZones.forEach((country) => {
      finalTimeZonesArray.push({
        value: country.toLowerCase(),
        label: country,
      });
    });
    setTimeZones(finalTimeZonesArray);
  };

  const clearCheckBoxes = () => {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
    setLocationsSelectedCount(0);
  };
  const isCheckboxSelected = (i) => {
    let isChecked;
    const checkboxArray = Array.from(
      document.querySelectorAll("input[type=checkbox].locations")
    );

    if (checkboxArray.length > 0) {
      isChecked = checkboxArray[i].checked === true;
    }
    return isChecked;
  };
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  };
  useEffect(() => {
    async function fetchLocations() {
      try {
        let response = await fetch("http://localhost:5000/locations");
        response = await response.json();
        setLocations(response);
        generateLocationsForFiltering(response);
        generateCountriesForFiltering(response);
        generateTimezonesForFiltering(response);
      } catch (error) {}
    }
    fetchLocations();
  }, []);
  return (
    <>
      <FilterAndSearchContainer>
        <StyledPlusIcon icon={faSquarePlus}></StyledPlusIcon>
        <FilterSection width={"500px"}>
          <SelectField
            value={selectedLocation}
            onChange={setSelectedLocation}
            options={uniqueLocations}
            placeholder={"Locations: All"}
            wide={true}
            styles={customStyles}
          />

          <SelectField
            value={selectedCountry}
            onChange={setSelectedCountry}
            options={countries}
            placeholder={"Countries:   All"}
            wide={true}
            styles={customStyles}
          />

          <SelectField
            value={selectedTimeZone}
            onChange={setSelectedTimeZone}
            options={timeZones}
            placeholder={"Time zone:   All"}
            wide={true}
            styles={customStyles}
          />
        </FilterSection>
        {locationsSelectedCount > 0 && (
          <DeleteAllAndEdit>
            <GridButton>
              <ButtonIcon color={"#F94C53"} icon={faTrash}></ButtonIcon>
            </GridButton>
            <ClearButton onClick={clearCheckBoxes}>
              <ButtonIcon icon={faClose}></ButtonIcon>
              Clear
            </ClearButton>
          </DeleteAllAndEdit>
        )}
        <ContractAndSearch>
          <SearchForm>
            <SearchInput
              type="text"
              placeholder="Search in locations"
            ></SearchInput>
          </SearchForm>
        </ContractAndSearch>
      </FilterAndSearchContainer>

      <GridScroller>
        <Grid tab="locations">
          <GridHeader></GridHeader>
          <GridHeader>Name</GridHeader>
          <GridHeader>Station</GridHeader>
          <GridHeader>Country</GridHeader>
          <GridHeader>Address</GridHeader>
          <GridHeader>Time zone</GridHeader>
          <GridHeader>Number of employees</GridHeader>
          <GridHeader></GridHeader>

          {locations.map(function(item, i) {
            return (
              <React.Fragment key={i}>
                <GridCheckbox
                  className="locations"
                  type="checkbox"
                  key={i}
                  onClick={(e) => {
                    e.target.checked
                      ? setLocationsSelectedCount(locationsSelectedCount + 1)
                      : setLocationsSelectedCount(locationsSelectedCount - 1);
                  }}
                />
                <GridTextBiggerBlue>{item.name}</GridTextBiggerBlue>
                <GridTextBiggerBlue>{item.station}</GridTextBiggerBlue>
                <EmployeesGridArea>{item.country}</EmployeesGridArea>
                <EmployeesGridArea>{item.address}</EmployeesGridArea>
                <EmployeesGridArea>{item.timeZone}</EmployeesGridArea>

                <GridHorizontalPhotos>
                  {item.arrayOfPhotos.forEach((photo, i) => {
                    if (i < 7) {
                      return (
                        <StationsEmployeesPhoto
                          src={photo}
                          key={i}
                        ></StationsEmployeesPhoto>
                      );
                    }
                    if (i === item.arrayOfPhotos.length - 1 && i > 6) {
                      return (
                        <StationsExtraEmployees key={i}>
                          +{i - 6}
                        </StationsExtraEmployees>
                      );
                    }
                  })}
                </GridHorizontalPhotos>
                <GridEditAndDelete isCheckboxSelected={isCheckboxSelected(i)}>
                  <GridButton>
                    <ButtonIcon color={"#2576e4"} icon={faEdit}></ButtonIcon>
                  </GridButton>
                  <GridButton>
                    <ButtonIcon icon={faTrash} color={"#F94C53"}></ButtonIcon>
                  </GridButton>
                </GridEditAndDelete>
              </React.Fragment>
            );
          })}
        </Grid>
      </GridScroller>
    </>
  );
};
