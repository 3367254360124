import React, { useState, useContext } from "react";
import {
  faSquarePlus,
  faEdit,
  faTrash,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

import {
  ContractAndSearch,
  GridTextBiggerBlue,
  GridTextSmaller,
  GridCheckbox,
  GridHeader,
  GridVerticalDuo,
  GridPhoto,
  FilterAndSearchContainer,
  FilterSection,
  Grid,
  GridEmpty,
  GridScroller,
  SearchForm,
  SearchInput,
  StationsCircle,
  StationsEmployeesPhoto,
  StationsExtraEmployees,
  StationsHr,
  StyledPlusIcon,
  GridHorizontalPhotos,
  GridEditAndDelete,
  GridButton,
  ButtonIcon,
  DeleteAllAndEdit,
  ClearButton,
} from "../../styledComponents.js";
import GlobalContext from "../../context/GlobalContext.jsx";
import {
  Checkbox,
  createTheme,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ThemeProvider,
} from "@material-ui/core";
import { getLocationCollection } from "../../utils.jsx";
export const Stations = () => {
  const [stationsSelectedCount, setStationsSelectedCount] = useState(0);

  const {
    selectedLocation,
    setSelectedLocation,
    stations,
    selectedStation,
    setSelectedStation,
  } = useContext(GlobalContext);

  const handleChangeLocation = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedLocation([]);
      return;
    }
    setSelectedLocation(event.target.value);
  };

  const handleChangeStation = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedStation([]);
      return;
    }
    setSelectedStation(event.target.value);
  };
  const checkTheFilters = (station) => {
    let pass = true;
    if (selectedLocation.length > 0) {
      pass = selectedLocation.some((l) => l === station.location);
      if (!pass) {
        return pass;
      }
    }
    if (selectedStation.length > 0) {
      pass = selectedStation.some((s) => s === station.stationName);
    }

    return pass;
  };
  const clearCheckBoxes = () => {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
    setStationsSelectedCount(0);
  };
  const isCheckboxSelected = (i) => {
    let isChecked;
    const checkboxArray = Array.from(
      document.querySelectorAll("input[type=checkbox].stations")
    );

    if (checkboxArray.length > 0) {
      if (checkboxArray[i]) {
        isChecked = checkboxArray[i].checked === true;
      }
    }
    return isChecked;
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3068d9",
        "&$checked": {
          main: "#3068d9",
        },
      },
    },
  });

  return (
    <>
      <FilterAndSearchContainer>
        <StyledPlusIcon icon={faSquarePlus}></StyledPlusIcon>
        <FilterSection width={"300px"}>
          <FormControl
            variant="outlined"
            size="small"
            style={{
              width: "120px",
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <InputLabel>Locations</InputLabel>
            <Select
              multiple
              value={selectedLocation}
              onChange={handleChangeLocation}
              MenuProps={{
                disableScrollLock: true,
                getContentAnchorEl: () => null,
              }}
              autoWidth
              renderValue={(selected) => selected.join(", ")}
              label="Locations"
              style={{ height: "38px" }}
            >
              <MenuItem value="None" key="location_none">
                <ListItemText primary="None"></ListItemText>
              </MenuItem>
              {getLocationCollection().map((element) => {
                return (
                  <MenuItem value={element.value} key={element.id}>
                    <ListItemIcon>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          color="primary"
                          checked={selectedLocation.indexOf(element.value) > -1}
                        />
                      </ThemeProvider>
                    </ListItemIcon>

                    <ListItemText primary={element.value}></ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            style={{
              width: "120px",
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <InputLabel>Stations</InputLabel>
            <Select
              multiple
              value={selectedStation}
              onChange={handleChangeStation}
              MenuProps={{
                disableScrollLock: true,
                getContentAnchorEl: () => null,
              }}
              autoWidth
              renderValue={(selected) => selected.join(", ")}
              label="Stations"
              style={{ height: "38px" }}
            >
              <MenuItem value="None" key="station_none">
                <ListItemText primary="None"></ListItemText>
              </MenuItem>
              {stations.map((element) => {
                return (
                  <MenuItem value={element.stationName} key={element.id}>
                    <ListItemIcon>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          color="primary"
                          checked={
                            selectedStation.indexOf(element.stationName) > -1
                          }
                        />
                      </ThemeProvider>
                    </ListItemIcon>

                    <ListItemText primary={element.stationName}></ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </FilterSection>
        {stationsSelectedCount > 0 && (
          <DeleteAllAndEdit>
            <GridButton>
              <ButtonIcon color={"#F94C53"} icon={faTrash}></ButtonIcon>
            </GridButton>
            <ClearButton onClick={clearCheckBoxes}>
              <ButtonIcon icon={faClose}></ButtonIcon>
              Clear
            </ClearButton>
          </DeleteAllAndEdit>
        )}
        <ContractAndSearch>
          <SearchForm>
            <SearchInput
              type="text"
              placeholder="Search in stations"
            ></SearchInput>
          </SearchForm>
        </ContractAndSearch>
      </FilterAndSearchContainer>

      <GridScroller>
        <Grid tab="stations">
          <GridHeader></GridHeader>
          <GridHeader></GridHeader>
          <GridHeader>Name</GridHeader>
          <GridHeader></GridHeader>
          <GridHeader>HR</GridHeader>
          <GridHeader></GridHeader>
          <GridHeader>Number of employees</GridHeader>
          <GridHeader></GridHeader>

          {stations.map(function(item, i) {
            if (checkTheFilters(item))
              return (
                <React.Fragment key={i}>
                  <GridCheckbox
                    className="stations"
                    type="checkbox"
                    key={i}
                    onClick={(e) => {
                      e.target.checked
                        ? setStationsSelectedCount(stationsSelectedCount + 1)
                        : setStationsSelectedCount(stationsSelectedCount - 1);
                    }}
                  />
                  <StationsCircle color={item.color} />
                  <GridVerticalDuo>
                    <GridTextBiggerBlue>{item.stationName}</GridTextBiggerBlue>
                    <GridTextSmaller>{item.location}</GridTextSmaller>
                  </GridVerticalDuo>
                  <GridEmpty />
                  <StationsHr>
                    <GridPhoto src={item.hrPhoto}></GridPhoto>
                    <GridTextBiggerBlue>{item.hrName}</GridTextBiggerBlue>
                  </StationsHr>
                  <GridEmpty />
                  <GridHorizontalPhotos>
                    {item.arrayOfPhotos.map((photo, i) => {
                      if (i < 7) {
                        return (
                          <StationsEmployeesPhoto
                            src={photo}
                            key={i}
                          ></StationsEmployeesPhoto>
                        );
                      } else if (i === item.arrayOfPhotos.length - 1 && i > 6) {
                        return (
                          <StationsExtraEmployees key={i}>
                            +{i - 6}
                          </StationsExtraEmployees>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </GridHorizontalPhotos>
                  <GridEditAndDelete isCheckboxSelected={isCheckboxSelected(i)}>
                    <GridButton>
                      <ButtonIcon color={"#2576e4"} icon={faEdit}></ButtonIcon>
                    </GridButton>
                    <GridButton>
                      <ButtonIcon icon={faTrash} color={"#F94C53"}></ButtonIcon>
                    </GridButton>
                  </GridEditAndDelete>
                </React.Fragment>
              );
            else return <React.Fragment key={i}></React.Fragment>;
          })}
        </Grid>
      </GridScroller>
    </>
  );
};
