import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";

import {
  Content,
  Top,
  Navbar,
  NavbarHeader,
  NavbarLinksContainer,
  NavbarLinkInnerContainer,
  NavbarLink,
  NotificationsContainer,
  Circle,
  IconNotifications,
  ProfileImage,
  NotificationIconAndProfileContainer,
} from "../../styledComponents.js";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FullSchedule } from "./FullSchedule.jsx";
import scheduler from "../../images/scheduler.png";
import { FigmaImage } from "../../styledComponents";
const Scheduler = forwardRef((props, ref) => {
  const navbarLinkHeader = "Scheduler",
    navbarLinkFullSchedule = "Full Schedule",
    navbarLinkMySchedule = "My Schedule",
    navbarLinkPending = "Pending Approval",
    navbarLinkVacation = "Vacation",
    profileImage =
      "https://anima-uploads.s3.amazonaws.com/projects/62f4d31bf593149d8b2ce389/releases/62f4e504f3486458d6da122f/img/ellipse-31@2x.png";

  const [navActive, setNavActive] = useState(navbarLinkFullSchedule);

  useImperativeHandle(ref, () => ({
    setNavActiveFromParent() {
      setNavActive(navbarLinkFullSchedule);
    },
  }));

  const renderActiveTab = () => {
    switch (navActive) {
      case "Full Schedule":
        return <FullSchedule></FullSchedule>;
      case "My Schedule":
        return <FullSchedule></FullSchedule>;

      case "Pending Approval":
        return <FullSchedule></FullSchedule>;

      case "Vacation":
        return <FullSchedule></FullSchedule>;

      default:
        break;
    }
  };
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1400);

  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth > 1400);

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
   !isWideScreen ?   <FigmaImage src={scheduler} />

  :  (
    <Content>
      <Top>
        <Navbar>
          <NavbarHeader
            onClick={() => {
              setNavActive(navbarLinkFullSchedule);
            }}
          >
            {navbarLinkHeader}
          </NavbarHeader>
          <NavbarLinksContainer>
            <NavbarLink
              onClick={() => {
                setNavActive(navbarLinkFullSchedule);
              }}
              active={navActive === navbarLinkFullSchedule}
            >
              {navbarLinkFullSchedule}
            </NavbarLink>
            <NavbarLinkInnerContainer>
              <NavbarLink
                onClick={() => {
                  setNavActive(navbarLinkMySchedule);
                }}
                active={navActive === navbarLinkMySchedule}
              >
                {navbarLinkMySchedule}
              </NavbarLink>
            </NavbarLinkInnerContainer>
            <NavbarLink>
              <NavbarLinkInnerContainer>
                <NavbarLink
                  onClick={() => {
                    setNavActive(navbarLinkPending);
                  }}
                  active={navActive === navbarLinkPending}
                >
                  {navbarLinkPending}
                </NavbarLink>
              </NavbarLinkInnerContainer>
            </NavbarLink>
            <NavbarLink>
              <NavbarLinkInnerContainer>
                <NavbarLink
                  onClick={() => {
                    setNavActive(navbarLinkVacation);
                  }}
                  active={navActive === navbarLinkVacation}
                >
                  {navbarLinkVacation}
                </NavbarLink>
              </NavbarLinkInnerContainer>
            </NavbarLink>
          </NavbarLinksContainer>
          <NotificationIconAndProfileContainer>
            <NotificationsContainer>
              <Circle></Circle>
              <IconNotifications icon={faBell} />
            </NotificationsContainer>
            <ProfileImage src={profileImage} />
          </NotificationIconAndProfileContainer>
        </Navbar>
        {renderActiveTab()}
      </Top>
    </Content>
  ))
});
export default Scheduler;
