import React, { useState } from "react";
import { Chat } from "./Chat";

import {
  AttentionContainer,
  AttentionHeaderContainer,
  IconNotifications1,
  NotificationHeader,
  AttentionContent,
  ShiftIcon,
  ReminderShift,
  BoldText,
  RegularText,
  CloseIcon,
  HorizontalLine,
  NotificationsNavbar,
  NotificationsNavbarLinkContainer,
  NotificationsNavbarLink,
  ClearAllNotifications,
  NotificationsList,
  NotificationsListItem,
  NotificationsListIcon,
  ListPhoto,
  DigitalClock,
  Notifications,
  AcceptButtons,
  Yesterday,
  HomeContainer,
  NotificationsNavBarRedCircleAndNumber,
} from "../../styledComponents.js";
import {
  faCalendarDays,
  faFlag,
  faMessage,
  faClock,
  faBell,
  faClose,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
const Home = ({
  showNotificationsNumber,
  clearNotificationsNumberFromChild,
}) => {
  const attentionHeader =
      "Hello Elena, The following items need your attention!",
    reminderAction = "Your next shift - ",
    notificationsNavBarAll = "All",
    notificationsNavBarRedCircleNumber = "15",
    navbarLinkMe = "Me",
    navbarLinkOthers = "Others",
    navbarLinkPending = "Pending",
    navbarLinkNews = "News",
    navbarLinkMessages = "Messages",
    clearAllNotifications = "Clear all notifications",
    rojanPhoto =
      "https://anima-uploads.s3.amazonaws.com/projects/62f4d31bf593149d8b2ce389/releases/62f4e504f3486458d6da122f/img/ellipse-7-8@2x.png",
    actionMaker = "Rojer A. ",
    action = "booked shift for employee",
    sandraPhoto =
      "https://anima-uploads.s3.amazonaws.com/projects/62f4d31bf593149d8b2ce389/releases/62f4e504f3486458d6da122f/img/ellipse-33-10@2x.png",
    sandraR = " Sandra R.",
    exampleTime = "16:46",
    shiftUpdate = "You have a new shift - ",
    fullDateAndTime = "Monday, January 18 from 09:00 to 17:00",
    employee = "Employee",
    lateText = "was late 17 minutes",
    accept = "Accept",
    decline = "No",
    yesterday = "Yesterday",
    carlPhoto =
      "https://anima-uploads.s3.amazonaws.com/projects/62f4d31bf593149d8b2ce389/releases/62f4e504f3486458d6da122f/img/ellipse-90-8@2x.png",
    carl = "Carl Hamilton",
    dummyMessage = "Lorem Ipsum is simply dummy...",
    kristoferPhoto =
      "https://anima-uploads.s3.amazonaws.com/projects/62f4d31bf593149d8b2ce389/releases/62f4e504f3486458d6da122f/img/ellipse-91-8@2x.png",
    kristofer = "Kristofer Hamilton",
    vacationMessage = "wants to take a vacation from June 5 to June 15",
    headLine = "Heading",
    newsMessage =
      "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text...";
  const [notificationsNavActive, setNotificationsNavActive] = useState(
    notificationsNavBarAll
  );
  const [showAttention, setShowAttention] = useState(true);

  const notificationsListItems = [
    {
      icon: faCalendarDays,
      primaryPhoto: rojanPhoto,
      actionMaker: actionMaker,
      action: action,
      secondaryPhoto: sandraPhoto,
      secondaryBoldText: sandraR,
      topic: "bookings",
    },
    { icon: faCalendarDays, topic: "newShiftForUser" },
    {
      icon: faClock,
      topic: "late",
      primaryPhoto: sandraPhoto,
      lateBoldText: sandraR,
    },
    {
      icon: faMessage,
      topic: "message",
      primaryPhoto: carlPhoto,
      message: dummyMessage,
      creator: carl,
    },
    {
      icon: faFlag,
      topic: "vacation",
      primaryPhoto: kristoferPhoto,
      creator: kristofer,
      message: vacationMessage,
    },
    {
      icon: faNewspaper,
      topic: "news",
      headLine: headLine,
      message: newsMessage,
    },
  ];
  const notificationsListItemsColors = ["#f9f9f9", "#edf0ff", "#fff1e9"];

  const switchNotifications = (item) => {
    switch (item.topic) {
      case "newShiftForUser":
        return (
          <>
            <Notifications>
              <BoldText>{shiftUpdate}</BoldText>
              <RegularText>{fullDateAndTime}</RegularText>
            </Notifications>
            <DigitalClock>{exampleTime}</DigitalClock>
          </>
        );
      case "bookings":
        return (
          <>
            <Notifications>
              <ListPhoto src={item.primaryPhoto} />
              <BoldText>{item.actionMaker}</BoldText>
              <RegularText>{item.action}</RegularText>
              <ListPhoto src={item.secondaryPhoto} />
              <BoldText>{item.secondaryBoldText}</BoldText>
            </Notifications>
            <DigitalClock>{exampleTime}</DigitalClock>
          </>
        );
      case "late":
        return (
          <>
            <Notifications>
              <RegularText>{employee}</RegularText>
              <ListPhoto src={item.primaryPhoto} />

              <BoldText>{item.lateBoldText}</BoldText>
              <RegularText>{lateText}</RegularText>
              <AcceptButtons accept="true">{accept}</AcceptButtons>
              <AcceptButtons>{decline}</AcceptButtons>
            </Notifications>
            <DigitalClock>{exampleTime}</DigitalClock>
          </>
        );
      case "message":
        return (
          <>
            <Notifications>
              <ListPhoto src={item.primaryPhoto} />
              <BoldText>{item.creator}</BoldText>

              <RegularText>{item.message}</RegularText>
            </Notifications>
            <DigitalClock>{exampleTime}</DigitalClock>
          </>
        );
      case "vacation":
        return (
          <>
            <Notifications>
              <ListPhoto src={item.primaryPhoto} />
              <BoldText>{item.creator}</BoldText>

              <RegularText>{item.message}</RegularText>
              <AcceptButtons accept="true">{accept}</AcceptButtons>
              <AcceptButtons>{decline}</AcceptButtons>
            </Notifications>
            <DigitalClock>{exampleTime}</DigitalClock>
          </>
        );
      case "news":
        return (
          <>
            <Notifications>
              <RegularText>{item.message}</RegularText>
            </Notifications>
            <DigitalClock>{exampleTime}</DigitalClock>
          </>
        );
      default:
        break;
    }
  };

  const renderNotifications = (item, i) => {
    return (
      <div key={i}>
        <NotificationsListItem color={notificationsListItemsColors[i % 3]}>
          <NotificationsListIcon icon={item.icon} />
          {switchNotifications(item)}
        </NotificationsListItem>
        {i === 2 && <Yesterday>{yesterday}</Yesterday>}
      </div>
    );
  };
  return (
    <HomeContainer>
      <AttentionContainer show={showAttention}>
        <AttentionHeaderContainer>
          <IconNotifications1 icon={faBell} />
          <NotificationHeader>{attentionHeader}</NotificationHeader>
        </AttentionHeaderContainer>
        <AttentionContent>
          <ShiftIcon icon={faCalendarDays} />
          <ReminderShift>
            <BoldText>{reminderAction}</BoldText>
            <RegularText>{fullDateAndTime}</RegularText>
          </ReminderShift>
          <CloseIcon
            icon={faClose}
            onClick={() => {
              setShowAttention(false);
            }}
          />
        </AttentionContent>
      </AttentionContainer>
      <HorizontalLine />
      <NotificationsNavbar>
        <NotificationsNavbarLinkContainer>
          <NotificationsNavbarLink
            onClick={() => {
              setNotificationsNavActive(notificationsNavBarAll);
            }}
            active={notificationsNavActive === notificationsNavBarAll}
          >
            {notificationsNavBarAll}
            <NotificationsNavBarRedCircleAndNumber
              show={showNotificationsNumber}
            >
              {notificationsNavBarRedCircleNumber}
            </NotificationsNavBarRedCircleAndNumber>
          </NotificationsNavbarLink>

          <NotificationsNavbarLink
            onClick={() => {
              setNotificationsNavActive(navbarLinkMe);
            }}
            active={notificationsNavActive === navbarLinkMe}
          >
            {navbarLinkMe}
          </NotificationsNavbarLink>
          <NotificationsNavbarLink
            onClick={() => {
              setNotificationsNavActive(navbarLinkOthers);
            }}
            active={notificationsNavActive === navbarLinkOthers}
          >
            {navbarLinkOthers}
          </NotificationsNavbarLink>
          <NotificationsNavbarLink
            onClick={() => {
              setNotificationsNavActive(navbarLinkPending);
            }}
            active={notificationsNavActive === navbarLinkPending}
          >
            {navbarLinkPending}
          </NotificationsNavbarLink>
          <NotificationsNavbarLink
            onClick={() => {
              setNotificationsNavActive(navbarLinkNews);
            }}
            active={notificationsNavActive === navbarLinkNews}
          >
            {navbarLinkNews}
          </NotificationsNavbarLink>
          <NotificationsNavbarLink
            onClick={() => {
              setNotificationsNavActive(navbarLinkMessages);
            }}
            active={notificationsNavActive === navbarLinkMessages}
          >
            {navbarLinkMessages}
          </NotificationsNavbarLink>
        </NotificationsNavbarLinkContainer>

        <ClearAllNotifications
          onClick={() => {
            clearNotificationsNumberFromChild(!showNotificationsNumber);
          }}
          show={showNotificationsNumber}
        >
          {clearAllNotifications}
        </ClearAllNotifications>
      </NotificationsNavbar>
      <HorizontalLine />
      {notificationsNavActive === notificationsNavBarAll ? (
        <NotificationsList>
          {notificationsListItems.map(function (item, i) {
            return renderNotifications(item, i);
          })}
        </NotificationsList>
      ) : (
        <Chat
          carlPhoto={carlPhoto}
          dummyMessage={dummyMessage}
          creator={actionMaker}
          exampleTime={exampleTime}
          yesterday={yesterday}
        ></Chat>
      )}
    </HomeContainer>
  );
};

export default Home;
