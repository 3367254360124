import {
  faCheck,
  faClose,
  faCopy,
  faEdit,
  faSquarePlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Checkbox,
  createTheme,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/GlobalContext";
import {
  ButtonIcon,
  CalendarHeader,
  ClearButton,
  DeleteAllAndEdit,
  FilterSection,
  GridButton,
  SchedulerHeaderButton,
  StyledPlusIcon,
} from "../../styledComponents";
import { getLocationCollection } from "../../utils";
export default function FullSchedulerThirdHeaderBar() {
  const {
    setShowSchedulerModal,
    dayInScheduleSelected,
    setDayInScheduleSelected,
    shifts,
    setShifts,
    setShiftForEdit,
    employees,
    selectedEmployee,
    stations,
    setSelectedEmployee,
    selectedLocation,
    setSelectedLocation,
    selectedStation,
    setSelectedStation,
  } = useContext(GlobalContext);
  const [employeesForFiltering, setEmployeesForFiltering] = useState([]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3068d9",
        "&$checked": {
          main: "#3068d9",
        },
      },
    },
  });

  const clearCheckBoxes = () => {
    setDayInScheduleSelected([]);
  };

  const deleteShifts = async () => {
    dayInScheduleSelected.map(async (day) => {
      const shiftToRemove = shifts.filter((s) => s.date === day.value);
      try {
        await fetch(`http://localhost:5000/shifts/${shiftToRemove[0].id}`, {
          method: "DELETE",
        });
        try {
          let response = await fetch("http://localhost:5000/shifts");
          response = await response.json();
          setShifts(response);
        } catch (error) {}
      } catch (error) {}
    });
  };

  const prepareForEdit = () => {
    const selectedShift = shifts.filter(
      (s) => s.date === dayInScheduleSelected[0].value
    );
    setShiftForEdit(selectedShift[0]);
  };

  const handleChangeEmployee = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedEmployee([]);
      return;
    }
    setSelectedEmployee(event.target.value);
  };
  const handleChangeLocation = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedLocation([]);
      return;
    }
    setSelectedLocation(event.target.value);
  };
  const handleChangeStation = (event) => {
    if (event.target.value[event.target.value.length - 1] === "None") {
      setSelectedStation([]);
      return;
    }
    setSelectedStation(event.target.value);
  };
  useEffect(() => {
    const generateEmployeesForFiltering = () => {
      const tempEmployeeArray = [];
      if (employees[0].id) {
        employees.forEach((employee) => {
          tempEmployeeArray.push({
            name: employee.employeeName,
            id: employee.id,
          });
        });
      }
      setEmployeesForFiltering(tempEmployeeArray);
    };

    generateEmployeesForFiltering();
  }, [employees]);

  return (
    <CalendarHeader>
      <StyledPlusIcon
        icon={faSquarePlus}
        onClick={() => setShowSchedulerModal(true)}
      ></StyledPlusIcon>
      <SchedulerHeaderButton width={"130px"} color={"#7bce90"}>
        <ButtonIcon icon={faCheck} color={"#fcfcfc"}></ButtonIcon>
        Publish
      </SchedulerHeaderButton>
      <FilterSection width={"544px"}>
        <FormControl
          variant="outlined"
          size="small"
          style={{
            width: "120px",
            display: "flex",
            marginLeft: "20px",
          }}
        >
          <InputLabel>Employees</InputLabel>
          <Select
            multiple
            value={selectedEmployee}
            onChange={handleChangeEmployee}
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: () => null,
            }}
            autoWidth
            renderValue={(selected) => selected.join(", ")}
            label="Employees"
            style={{ height: "38px" }}
          >
            <MenuItem value="None" key="employee_none">
              <ListItemText primary="None"></ListItemText>
            </MenuItem>
            {employeesForFiltering.map((element) => {
              return (
                <MenuItem value={element.name} key={element.id}>
                  <ListItemIcon>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        color="primary"
                        checked={selectedEmployee.indexOf(element.name) > -1}
                      />
                    </ThemeProvider>
                  </ListItemIcon>

                  <ListItemText primary={element.name}></ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          style={{
            width: "120px",
            display: "flex",
            marginLeft: "20px",
          }}
        >
          <InputLabel>Locations</InputLabel>
          <Select
            multiple
            value={selectedLocation}
            onChange={handleChangeLocation}
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: () => null,
            }}
            autoWidth
            renderValue={(selected) => selected.join(", ")}
            label="Locations"
            style={{ height: "38px" }}
          >
            <MenuItem value="None" key="location_none">
              <ListItemText primary="None"></ListItemText>
            </MenuItem>
            {getLocationCollection().map((element) => {
              return (
                <MenuItem value={element.value} key={element.id}>
                  <ListItemIcon>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        color="primary"
                        checked={selectedLocation.indexOf(element.value) > -1}
                      />
                    </ThemeProvider>
                  </ListItemIcon>

                  <ListItemText primary={element.value}></ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          size="small"
          style={{
            width: "120px",
            display: "flex",
            marginLeft: "20px",
          }}
        >
          <InputLabel>Stations</InputLabel>
          <Select
            multiple
            value={selectedStation}
            onChange={handleChangeStation}
            MenuProps={{
              disableScrollLock: true,
              getContentAnchorEl: () => null,
            }}
            autoWidth
            renderValue={(selected) => selected.join(", ")}
            label="Stations"
            style={{ height: "38px" }}
          >
            <MenuItem value="None" key="station_none">
              <ListItemText primary="None"></ListItemText>
            </MenuItem>
            {stations.map((element) => {
              return (
                <MenuItem value={element.stationName} key={element.id}>
                  <ListItemIcon>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        color="primary"
                        checked={
                          selectedStation.indexOf(element.stationName) > -1
                        }
                      />
                    </ThemeProvider>
                  </ListItemIcon>

                  <ListItemText primary={element.stationName}></ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </FilterSection>

      {dayInScheduleSelected.length > 0 && dayInScheduleSelected.length < 2 && (
        <DeleteAllAndEdit>
          <GridButton>
            <ButtonIcon
              color={"#2576e4"}
              icon={faEdit}
              onClick={() => {
                setShowSchedulerModal(true);
                prepareForEdit();
              }}
            ></ButtonIcon>
          </GridButton>
          <GridButton>
            <ButtonIcon color={"#2576e4"} icon={faCopy}></ButtonIcon>
          </GridButton>
          <GridButton>
            <ButtonIcon
              color={"#F94C53"}
              icon={faTrash}
              onClick={deleteShifts}
            ></ButtonIcon>
          </GridButton>
          <ClearButton onClick={clearCheckBoxes}>
            <ButtonIcon icon={faClose}></ButtonIcon>
            Clear
          </ClearButton>
        </DeleteAllAndEdit>
      )}

      {dayInScheduleSelected.length > 1 && (
        <DeleteAllAndEdit>
          <GridButton>
            <ButtonIcon
              color={"#F94C53"}
              icon={faTrash}
              onClick={deleteShifts}
            ></ButtonIcon>
          </GridButton>
          <ClearButton onClick={clearCheckBoxes}>
            <ButtonIcon icon={faClose}></ButtonIcon>
            Clear
          </ClearButton>
        </DeleteAllAndEdit>
      )}
    </CalendarHeader>
  );
}
