import React, { useEffect, useState } from "react";
import GlobalContext from "./GlobalContext";
import dayjs from "dayjs";
export default function ContextWrapper(props) {
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showSchedulerModal, setShowSchedulerModal] = useState(null);
  const [shifts, setShifts] = useState([{}]);
  const [employees, setEmployees] = useState([{ stations: [] }]);
  const [stations, setStations] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedStation, setSelectedStation] = useState([]);
  const [selectedRange, setSelectedRange] = useState("Month");
  const [weekIndex, setWeekIndex] = useState(dayjs().week() - 1);
  const [dayInScheduleSelected, setDayInScheduleSelected] = useState([]);
  const [currentWeek, setCurrentWeek] = useState([]);

  const [shiftForEdit, setShiftForEdit] = useState(null);
  useEffect(() => {
    smallCalendarMonth != null && setMonthIndex(smallCalendarMonth);
  }, [smallCalendarMonth]);

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        weekIndex,
        setWeekIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showSchedulerModal,
        setShowSchedulerModal,
        shifts,
        setShifts,
        employees,
        setEmployees,
        stations,
        setStations,
        selectedRole,
        setSelectedRole,
        selectedEmployee,
        setSelectedEmployee,
        selectedLocation,
        setSelectedLocation,
        selectedStation,
        setSelectedStation,
        dayInScheduleSelected,
        setDayInScheduleSelected,
        shiftForEdit,
        setShiftForEdit,
        selectedRange,
        setSelectedRange,
        currentWeek,
        setCurrentWeek,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
