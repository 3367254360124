import React, { useState, useContext, useEffect } from "react";
import { HorizontalLine, SchedulerContainer } from "../../styledComponents";
import { getMonth, getWeek } from "../../utils";
import Month from "./Month";
import FullSchedulerHeaderBar from "./FullSchedulerHeaderBar";
import GlobalContext from "../../context/GlobalContext";
import SchedulerEventModal from "./SchedulerEventModal";
import FullSchedulerSecondHeaderBar from "./FullSchedulerSecondHeaderBar";
import FullSchedulerThirdHeaderBar from "./FullSchedulerThirdHeaderBar";
import Week from "./Week";
import { API } from "aws-amplify";
import { listShifts } from "../../graphql/queries";
export function FullSchedule() {
  const [currentMonth, setCurrentMonth] = useState(getMonth());

  const {
    monthIndex,
    weekIndex,
    showSchedulerModal,
    setShifts,
    selectedRange,
    setCurrentWeek,
  } = useContext(GlobalContext);

  useEffect(() => {
    const fetchShifts = async () => {
      try {
        /* let response = await fetch("http://localhost:5000/shifts");
        response = await response.json(); */
        let response = await API.graphql({ query: listShifts });
        setShifts(response.data.listShifts.items);
      } catch (error) {}
    };
    fetchShifts();

    setCurrentMonth(getMonth(monthIndex));
    setCurrentWeek(getWeek(weekIndex));
  }, [monthIndex, weekIndex, setCurrentWeek, setShifts]);
  return (
    <SchedulerContainer>
      {showSchedulerModal && <SchedulerEventModal></SchedulerEventModal>}
      <FullSchedulerHeaderBar />
      {selectedRange === "Month" && (
        <>
          <FullSchedulerSecondHeaderBar />
          <HorizontalLine></HorizontalLine>
        </>
      )}

      <FullSchedulerThirdHeaderBar />
      <HorizontalLine></HorizontalLine>
      {selectedRange === "Month" ? (
        <Month month={currentMonth}></Month>
      ) : (
        <Week />
      )}
    </SchedulerContainer>
  );
}
