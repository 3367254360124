import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

const Modal = (props) => {
  const { children, showModal } = props;

  return (
    <Dialog open={showModal}>
      <DialogTitle>Invite users</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
