import React, { useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import Button from "../controls/Button";
import { useForm, Form } from "../DashboardContainer/UseForm";
import Input from "../controls/Input";
import Select from "../controls/Select";
import GlobalContext from "../../context/GlobalContext";
import { getLocationCollection, getStationCollection } from "../../utils";
import { API } from "aws-amplify";
import { createShift as createShiftMutation } from "../../graphql/mutations";
import { listShifts } from "../../graphql/queries";

const initialFValues = {
  id: 0,
  date: "",
  shiftStart: "",
  shiftEnd: "",
  employeeId: "",
  stationId: "",
  locationId: "",
};

export default function SchedulerAddForm() {
  const {
    daySelected,
    setShowSchedulerModal,
    setShifts,
    employees,
    shiftForEdit,
    setShiftForEdit,
  } = useContext(GlobalContext);

  const addOrEditShift = async (e) => {
    const body = {
      date: daySelected.format("DD-MM-YY"),
      shiftStart: e.target[2].value,
      shiftEnd: e.target[4].value,
      employeeId: e.target[6].value,
      stationId: e.target[8].value,
      locationId: e.target[10].value,
      id: daySelected.format("DD-MM-YY"),
    };
    if (shiftForEdit == null) {
      await API.graphql({
        query: createShiftMutation,
        variables: { input: body },
      });

      /* setShifts(response.data.); */
      try {
        /* await fetch("http://localhost:5000/shifts", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }); */
      } catch (error) {}
    } else {
      try {
        await fetch(`http://localhost:5000/shifts/${body.id}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        setShiftForEdit(null);
      } catch (error) {}
    }
    try {
      /* let response = await fetch("http://localhost:5000/shifts");
      response = await response.json(); */
      let response = await API.graphql({ query: listShifts });
      setShifts(response.data.listShifts.items);
    } catch (error) {}
    /* try {
      let response = await fetch("http://localhost:5000/shifts");
      response = await response.json();
      setShifts(response);
    } catch (error) {} */
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("date" in fieldValues)
      temp.shiftEnd = fieldValues.shiftEnd ? "" : "This field is required.";
    if ("shiftStart" in fieldValues)
      temp.shiftStart = fieldValues.shiftStart ? "" : "This field is required.";
    if ("shiftEnd" in fieldValues)
      temp.shiftEnd = fieldValues.shiftEnd ? "" : "This field is required.";
    if ("employeeId" in fieldValues)
      temp.employeeId =
        fieldValues.employeeId.length !== 0 ? "" : "This field is required.";
    if ("stationId" in fieldValues)
      temp.stationId =
        fieldValues.stationId.length !== 0 ? "" : "This field is required.";
    if ("locationId" in fieldValues)
      temp.locationId =
        fieldValues.locationId.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const generateEmployeeCollection = () => {
    return employees.map((e) => ({ id: e.id, title: e.employeeName }));
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEditShift(e);

      resetForm();
      setShowSchedulerModal(false);
    }
  };

  useEffect(() => {
    if (shiftForEdit != null)
      setValues({
        ...shiftForEdit,
      });
  }, [shiftForEdit, setValues]);
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Input
            name="date"
            label="Date"
            value={daySelected.format("ddd, MMM, DD")}
            onChange={handleInputChange}
            error={errors.date}
          />
          <Input
            label="Shift start"
            name="shiftStart"
            value={values.shiftStart}
            onChange={handleInputChange}
            error={errors.shiftStart}
          />
          <Input
            label="Shift end"
            name="shiftEnd"
            value={values.shiftEnd}
            onChange={handleInputChange}
            error={errors.shiftEnd}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            name="employeeId"
            label="Employee"
            value={values.employeeId}
            onChange={handleInputChange}
            options={generateEmployeeCollection()}
            error={errors.employeeId}
          />
          <Select
            name="stationId"
            label="Station"
            value={values.stationId}
            onChange={handleInputChange}
            options={getStationCollection()}
            error={errors.stationId}
          />
          <Select
            name="locationId"
            label="Location"
            value={values.locationId}
            onChange={handleInputChange}
            options={getLocationCollection()}
            error={errors.locationId}
          />

          <Button type="submit" text="Add shift" />
        </Grid>
      </Grid>
    </Form>
  );
}
