import dayjs from "dayjs";
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

export function getMonth(month = dayjs().month()) {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}
export function getWeek(week = dayjs().week()) {
  week = Math.floor(week);
  const year = dayjs().year();
  const dayNumberOfYear = (1 + week - 2) * 7;
  const approximateDay = new Date(year, 0, dayNumberOfYear);
  const dayOfWeek = approximateDay.getDay();
  const dayThatStartsWeek = approximateDay;
  if (dayOfWeek <= 4)
    dayThatStartsWeek.setDate(
      approximateDay.getDate() - approximateDay.getDay() + 1
    );
  else
    dayThatStartsWeek.setDate(
      approximateDay.getDate() + 8 - approximateDay.getDay()
    );
  const daysMatrix = new Array(7).fill(null).map((day, index) => {
    index === 0
      ? dayThatStartsWeek.setDate(dayThatStartsWeek.getDate())
      : dayThatStartsWeek.setDate(dayThatStartsWeek.getDate() + 1);
    return dayjs(new Date(dayThatStartsWeek));
  });
  return daysMatrix;
}
export const getStationCollection = () => [
  { id: "1", title: "Lead PHP" },
  { id: "2", title: "Security" },
  { id: "3", title: "Management" },
  { id: "4", title: "Production" },
  { id: "5", title: "Service" },
];
export const getRoleCollection = () => [
  { id: "1", title: "Employee", value: "Employee" },
  { id: "2", title: "Admin", value: "Admin" },
];

export const getLocationCollection = () => [
  { id: "1", value: "McDonalds", title: "McDonalds" },
  { id: "2", value: "Apple", title: "Apple" },
  { id: "3", value: "Junk", title: "Junk yard" },
  { id: "4", value: "Lycka", title: "Lycka" },
  { id: "5", value: "Transformer", title: "Transformer" },
  { id: "6", value: "Lully", title: "Lully" },
  { id: "7", value: "Ribonstack", title: "Ribonstack" },
  { id: "8", value: "True", title: "True store" },
  { id: "9", value: "Halmstad", title: "Halmstad" },
];
export const getLocationName = (id) => {
  let location = "";
  switch (id) {
    case "1":
      location = "McDonalds";
      break;
    case "2":
      location = "Apple";
      break;
    case "3":
      location = "Junk yard";
      break;
    case "4":
      location = "Lycka";
      break;
    case "5":
      location = "Transformer";
      break;
    case "6":
      location = "Lully";
      break;
    case "7":
      location = "Ribonstack";
      break;
    case "8":
      location = "True store";
      break;
    case "9":
      location = "Halmstad";
      break;
    default:
      break;
  }
  return location;
};
export const getStationName = (id) => {
  let station = "";
  switch (id) {
    case "1":
      station = "Lead PHP";
      break;
    case "2":
      station = "Security";
      break;
    case "3":
      station = "Management";
      break;
    case "4":
      station = "Production";
      break;
    case "5":
      station = "Service";
      break;
    default:
      break;
  }
  return station;
};
