import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

import { Link } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';

export const LeftSide = styled.div`
  width: ${(props) => (props.hideSidePanel ? '2%' : '9%')};
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: ${(props) => (props.hideSidePanel ? '' : '100px')};
`;

export const Logo = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const SideNavBar = styled.div`
  height: 400px;
  margin-top: 51px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.hideSidePanel ? 'center' : 'none')};
`;
export const SideNavBarLink = styled(Link)`
  align-items: center;
  display: flex !important;
  margin-top: ${(props) => (props.top ? '0px' : '34px')};
  color: ${(props) => (props.active ? '#3068d9' : '#7b7777')};
  font-weight: ${(props) => (props.active ? 700 : 400)};
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 1000px) {
    margin-top: ${(props) => (props.top ? '0px' : '10px')};
  }
`;
export const HideSideBar = styled.div`
  align-items: center;
  display: flex;
  margin-top: ${(props) => (props.top ? '0px' : '34px')};
  color: #aea5a5;
  cursor: pointer;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  @media (max-width: 1000px) {
    width: 12px;
    height: 12px;
  }
`;
export const StyledPlusIcon = styled(FontAwesomeIcon)`
  width: 40px;
  height: 40px;
  color: var(--blue);
  display: flex;
  margin-left: 20px;
  align-self: center;
  cursor: pointer;
`;

export const SideNavBarText = styled.div`
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 700;
  font-style: normal;
  height: 20px;
  margin-left: 5px;
  line-height: 20px;
  white-space: nowrap;
  display: ${(props) => (props.hideSidePanel ? 'none' : 'flex')};
  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
`;

export const GridWeekVerticalWrapper = styled.div`
  font-weight: 700;

  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Navbar = styled.div`
  margin-right: 2px;
  display: flex;
  align-items: center;
  height: 80px;
  width: 90%;
`;

export const NavbarHeader = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 700;
  font-style: normal;
  height: 20px;
  margin-top: 2px;
  color: var(--blackdefault);
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;
export const NavbarLinksContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-evenly;
`;

export const NavbarLinkInnerContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const NavbarLink = styled.div`
  height: 20px;

  font-weight: 700;
  color: ${(props) => (props.active ? '#3068d9' : 'black')};
  cursor: pointer;

  line-height: 20px;
  white-space: nowrap;
  @media (max-width: 1000px) {
    font-size: 0px;
  }
`;

export const Count = styled.span`
  color: var(--grey);
  margin-left: 3px;
  @media (max-width: 1000px) {
    font-size: 0px;
  }
`;

export const NavbarLinkLocation3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  margin-left: 37px;
  margin-top: 2px;
  font-weight: 400;
  color: transparent;
  line-height: 20px;
  white-space: nowrap;
`;

export const NotificationIconAndProfileContainer = styled.div`
  width: 180px;
  height: 44px;

  position: absolute;
  right: 5%;
  display: flex;
  @media (max-width: 1000px) {
    right: -12%;
  }
`;

export const NotificationsContainer = styled.div`
  width: 25%;

  cursor: pointer;
`;

export const Circle = styled.div`
  border: 2px solid var(--grey);
  position: absolute;
  width: 40px;
  height: 40px;
  top: 4px;
  left: 0;
  border-radius: 20px;
`;

export const IconNotifications = styled(FontAwesomeIcon)`
  position: absolute;
  width: 20px;
  height: 22px;
  top: 13px;
  left: 10px;
`;

export const RedCircle = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 6px;
  left: 28px;
  background-color: var(--alertRed);
  border-radius: 50%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Number = styled.p`
  color: #ffffff;
  font-size: 8px;
  font-weight: 700;
  font-style: normal;

  display: ${(props) => (props.show ? 'block' : 'none')};
`;

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  align-self: center;
  object-fit: cover;
  cursor: pointer;
`;

export const AttentionContainer = styled.div`
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
`;

export const AttentionHeaderContainer = styled.div`
  margin-top: 29px;
  margin-left: 20px;
  display: flex;
  align-items: flex-end;
  @media (max-width: 1000px) {
    margin-top: 10px;
    margin-left: 5px;
  }
`;

export const IconNotifications1 = styled(FontAwesomeIcon)`
  width: 20px;
  height: 22px;
  margin-bottom: 0.5px;
  align-self: center;
  @media (max-width: 1000px) {
    width: 10px;
    height: 11px;
  }
`;

export const NotificationHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  height: 27px;
  margin-left: 17px;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
  @media (max-width: 1000px) {
    font-size: 8px;
  }
`;

export const AttentionContent = styled.div`
  height: 56px;
  margin-top: 14px;
  display: flex;
  padding: 16px 20.3px;
  align-items: center;
  width: 100%;
  background-color: #bee0eb;
  @media (max-width: 1000px) {
    padding: 4px;
  }
`;

export const ShiftIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  @media (max-width: 1000px) {
    width: 10px;
    height: 10px;
  }
`;

export const ReminderShift = styled.p`
  height: 20px;
  margin-left: 19px;
  font-weight: 700;
  color: var(--blackdefault);
  text-align: center;
  line-height: 20px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    font-size: 8px;
  }
`;

export const BoldText = styled.span`
  font-weight: 700;
  margin-right: 10px;
  color: black;
  @media (max-width: 1000px) {
    margin-right: 2px;
  }
`;

export const RegularText = styled.span`
  font-weight: 400;
  margin-right: 10px;
  @media (max-width: 1000px) {
    margin-right: 2px;
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  width: 13px;
  height: 13px;
  position: absolute;
  right: 10%;
  cursor: pointer;
  @media (max-width: 1000px) {
    position: relative;
    right: 0%;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  border-bottom: 1px solid lightgrey;
`;
export const VerticalLine = styled.div`
  width: 1px;
  height: 100vh;
  border-left: 1px solid lightgrey;
`;

export const NotificationsNavbar = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 1000px) {
    font-size: 10px;
  }
`;

export const NotificationsNavbarLinkContainer = styled.div`
  display: flex;
  width: 60%;
  place-content: space-evenly;
  @media (max-width: 1000px) {
    width: 80%;
  }
`;
export const NotificationsNavbarLink = styled.div`
  height: 20px;
  margin-bottom: 8px;
  font-weight: 700;
  color: ${(props) => (props.active ? '#3068d9' : 'black')};
  line-height: 20px;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
`;

export const NotificationsNavBarRedCircleAndNumber = styled.p`
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-size: 8px;
  font-weight: 700;
  font-style: normal;
  position: relative;
  height: 15px;
  width: 15px;
  align-self: center;
  background-color: var(--alertRed);
  border-radius: 50%;
  align-items: center;
  margin-left: 5px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
`;

export const ClearAllNotifications = styled.div`
  flex-direction: column;
  justify-content: center;
  height: 20px;
  font-weight: 700;
  color: var(--grey);
  width: 160px;
  position: absolute;
  line-height: 20px;
  white-space: nowrap;
  right: 10%;
  padding: 3px;
  border: solid;
  align-items: center;
  cursor: pointer;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  @media (max-width: 1000px) {
    font-size: 0px;
    border: none;
  }
`;

export const NotificationsList = styled.ul``;
export const NotificationsListItem = styled.li`
  height: 59px;
  margin-top: 20px;
  display: flex;
  padding: 13px 20px;
  align-items: center;
  background-color: ${(props) => props.color};
  width: 100%;
  position: relative;
  @media (max-width: 1000px) {
    padding: 2%;
    margin-top: 10px;
  }
`;

export const NotificationsListIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  @media (max-width: 1000px) {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }
`;

export const ListPhoto = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 8px;
  @media (max-width: 1000px) {
    width: 16px;
    height: 16px;
  }
`;

export const DigitalClock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  letter-spacing: 0;
  font-weight: 400;
  font-style: normal;
  @media (max-width: 1000px) {
    font-size: 8px;
  }
`;

export const Notifications = styled.div`
  align-items: center;
  display: flex;
  width: 90%;
  @media (max-width: 1000px) {
    font-size: 8px;
  }
`;

export const AcceptButtons = styled.button`
  height: 40px;
  padding: 8px 15px;
  color: ${(props) => (props.accept ? 'white' : '#4967fb')};
  background-color: ${(props) => (props.accept ? '#4967fb' : 'white')};

  border-radius: 7px;
  border-style: none;
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: 1000px) {
    font-size: 8px;
    height: 18px;
    padding: 4px;
    margin-right: 2px;
  }
`;

export const Yesterday = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  align-self: flex-start;
  margin-top: 24px;
  margin-left: 20px;
  font-weight: 700;
  color: var(--grey);
  line-height: 20px;
  white-space: nowrap;
`;
export const GridRow = styled.div``;

export const GridCheckbox = styled.input`
  width: 20px;
  height: 20px;
  margin-left: 20px;
  cursor: pointer;
  @media (max-width: 1000px) {
    width: 10px;
    height: 10px;
  }
`;
export const GridPhoto = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 8px;
`;

export const GridVerticalDuo = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  place-content: space-evenly;
`;
export const GridTextBiggerBlue = styled.p`
  font-size: 15px;
  color: blue;
`;
export const GridTextSmaller = styled.p`
  font-size: 13px;
`;
export const EmployeesGridArea = styled.div``;

const getStationBackGroundColor = (station) => {
  switch (station) {
    case 'Group':
      return '#bfebff';
    case 'Service':
      return '#e2ffee';
    case 'Kitchen':
      return '#e3e8f5';
    case 'Lead PHP':
      return '#ffe1e4';
    default:
      break;
  }
};
const getStationBackFontColor = (station) => {
  switch (station) {
    case 'Group':
      return '#225fb5';
    case 'Service':
      return '#66b880';
    case 'Kitchen':
      return '#676297';
    case 'Lead PHP':
      return '#c83c33';
    default:
      break;
  }
};

export const GridHorizontalDuo = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 33%;
`;
export const GridHorizontalPhotos = styled.div`
  display: flex;
  position: relative;
  min-width: 300px;
`;

export const GridEditAndDelete = styled.div`
  display: flex;
  position: relative;
  width: auto;
  visibility: ${(props) => !props.isCheckboxSelected && 'hidden'};
`;

export const EmployeesStationsButton = styled.button`
  font-size: 13px;
  width: 75px;
  background-color: ${(props) => getStationBackGroundColor(props.station)};
  color: ${(props) => getStationBackFontColor(props.station)};
  border: none;
  height: 30px;
  margin: 0px 5px;
  border-radius: 5%;
`;

const generateGridFragments = (tab) => {
  switch (tab) {
    case 'employees':
      return '1fr 1fr 3fr 2fr 4fr 2fr 4fr 2fr 3fr';
    case 'stations':
      return '1fr 1fr 2fr 1fr 4fr 2fr 4fr 3fr';
    case 'locations':
      return '1fr 2fr 2fr 2fr 5fr 2fr 4fr 3fr';
    default:
      break;
  }
};

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) => generateGridFragments(props.tab)};
  align-items: center;
  grid-auto-rows: 70px;
`;
export const SchedulerModalWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  justify-items: center;
  place-content: center;
  z-index: 2;
`;
export const SchedulerForm = styled.div`
  background-color: white;
  border-radius: 10px;
  width: min-content;
  height: 90%;
`;

export const SchedulerFormHeader = styled.header`
  background-color: grey;
  border-radius: 10;
  padding: 20px;
  display: flex;
  justify-items: center;
`;

export const GridMonth = styled.div`
display: grid;
width: calc(100% - 40px);
grid-template-columns: ${(props) =>
  props.month === true
    ? '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
    : '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'};
align-items: center;
height: auto;
grid-auto-rows: 120px;
border: 1px solid #d6d6d6;
  grid-gap: 1px;
  background-color: #d6d6d6;
margin: 0 20px;
justify-items: center;
color: #d6d6d6;

  & > div {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;

  }
 
}

`;
export const GridWeekdayNames = styled.div`
  display: grid;
  width: calc(100% - 40px);
  grid-template-columns: ${(props) =>
    props.month === true
      ? '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
      : '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'};
  align-items: center;
  grid-template-rows: 30px;
  grid-gap: 1px;
  margin: 0 20px;
  color: #d6d6d6;

  & > p {
    padding: 10px;
  }
`;

export const SmallGridMonth = styled.div`
  display: grid;
  width: 24%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  height: 33vh;
  text-align: center;
  margin-bottom: 10%;
`;

export const SmallCalendarCapitalDay = styled.div`
  width: 100%;
`;

export const SchedulerContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const SchedulerHeaderStatsContainer = styled.div`
  text-align: center;
`;
export const FiguresStats = styled.p`
  font-format: bold;
  font-size: 16px;
  @media (max-width: 1000px) {
    font-size: 10px;
  }
`;

export const LabelFiguresStats = styled.p`
  font-size: 14px;
  font-weight: lighter;
  @media (max-width: 1000px) {
    font-size: 8px;
  }
`;

export const CalendarHeader = styled.div`
  display: flex;
  background-color: ${(props) => props.color};
  align-items: center;
  height: 72px;
  font-size: 30px;
  padding: 0 20px;
  place-content: ${(props) => props.evenly && 'space-between'};
`;
export const SmallCalendarHeader = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  font-size: 30px;
  padding-left: 20px;
`;
export const SmallCalendarButton = styled.button`
  background-color: white;
  border: none;
  cursor: pointer;
  color: ${(props) => props.today && 'white'};
  background-color: ${(props) => props.today && 'blue'};
  background-color: ${(props) => props.selected && 'lightblue'};
  border-radius: 50%;
  padding: 10px;
`;

export const SchedulerHeaderButton = styled.div`
  display: flex;
  width: ${(props) => props.width};
  height: 38px;
  justify-content: center;
  place-content: space-around;

  align-items: center;

  background-color: ${(props) => (props.color ? props.color : 'white')};
  color: ${(props) => props.color && 'white'};
  padding: 8px;
  border-radius: 5px;
  border: 1px solid grey;
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px;
`;

export const PDFAndBarsWrapper = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
`;

export const GridDay = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px;
  width: 100%;
  align-items: center;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
`;

export const GridDayNumberWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const GridDateCheckbox = styled(Checkbox)`
  && {
    padding: 0px;
  }
`;
export const GridThemeProvider = styled(ThemeProvider)``;

export const GridDateNumber = styled.div`
  color: ${(props) => props.today && 'blue'};
`;
const getStationBackGroundColorWithId = (stationId) => {
  switch (stationId) {
    case '1':
      return '#bfebff';
    case '2':
      return '#e2ffee';
    case '3':
      return '#e3e8f5';
    case '4':
      return '#ffe1e4';
    case '5':
      return '#ffe1e4';
    default:
      break;
  }
};
export const ShiftWrapper = styled.div`
  background-color: ${(props) =>
    getStationBackGroundColorWithId(props.stationId)};
  width: 100%;
  height: 80%;
  margin-top: 2%;
  padding: 1% 10% 3% 1%;
  place-content: space-evenly;
  display: flex;
  flex-direction: column;
  color: #000000;
  border-radius: 5px;
  font-size: 1.3vh;
`;

export const ShiftTime = styled.div`
  display: flex;
  align-items: center;
  height: 33%;
`;

export const ShiftTotal = styled.p`
  color: blue;
  margin-left: 5px;
`;

const getStationTextColor = (id) => {
  switch (id) {
    case '1':
      return '#225fb5';
    case '2':
      return '#66b880';
    case '3':
      return '#676297';
    case '4':
      return '#c83c33';
    case '5':
      return '#c83c33';
    default:
      break;
  }
};
export const ShiftStationLocation = styled.div`
  display: flex;
  align-items: center;
  height: 33%;
`;

export const ShiftStationName = styled.p`
  color: ${(props) => getStationTextColor(props.stationId)};
`;

export const GridHeader = styled.p`
  position: sticky;
  top: 0;
  color: lightgray;
  background-color: white;
  margin-bottom: 30px;
  display: flex;
  height: 60px;
  align-items: center;
`;

export const GridScroller = styled.div`
  overflow: scroll;
  height: 70vh;
  overflow-x: hidden;
  width: 100%;
`;
export const FilterAndSearchContainer = styled.div`
  background-color: #f9f9f9;
  height: 70px;
  display: flex;
  width: 100%;
  position: relative;
`;
export const SelectField = styled(Select)`
  width: ${(props) => (props.wide ? '32%' : '45%')};
  cursor: pointer;
`;

export const FilterSection = styled.div`
  display: flex;
  width: ${(props) => props.width};
  height: 100%;
  justify-content: center;
  place-content: space-between;
  align-items: center;
  margin-left: 10px;
`;

export const ContractAndSearch = styled.div`
display: flex;
width: 300px;
height: 100%
justify-content: center;
place-content: space-around;
align-items: center;
position: absolute;
right: 50px;
top: 0;
bottom: 0;
`;
export const Contract = styled.div`
  display: flex;
  width: 100px;
  height: 37px;

  justify-content: center;

  align-items: center;
  color: #dddddd;
  background-color: white;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid grey;
`;
export const ClearButton = styled.div`
  font-size: 17px;
  display: flex;
  width: 70px;
  height: 37px;

  justify-content: center;
  place-content: space-around;

  align-items: center;
  color: #dddddd;
  background-color: white;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid grey;
  cursor: pointer;
  margin-left: 10px;
`;

export const Search = styled.div`
  display: flex;
  width: 180px;
  height: 37px;

  justify-content: center;
  place-content: space-between;

  align-items: center;
  color: #dddddd;
  background-color: white;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid grey;
`;

export const DeleteAllAndEdit = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  place-content: space-between;
`;

export const GridButton = styled.div`
  display: flex;
  width: 38px;
  height: 38px;

  justify-content: center;
  place-content: space-around;

  align-items: center;
  color: #dddddd;
  background-color: white;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid grey;
  cursor: pointer;
  margin-left: 10px;
`;
export const ButtonIcon = styled(FontAwesomeIcon)`
width: 20px;
height: 20px;
color: ${(props) => props.color} };
margin-right: ${(props) => props.margin} };
cursor: pointer;
@media (max-width: 1000px) {
  width: 10px;
height: 10px;
}
`;

export const SearchForm = styled.form`
  display: flex;
  width: 60%;
  height: 37px;
  color: #dddddd;
  background-color: white;
  display: flex;
  position: relative;
  height: 38px;
  place-items: center;
  &:before {
    font-family: 'FontAwesome';
    content: '\f002';
    position: absolute;
    left: 6px;
    font-size: 20px;
  }
`;

export const SearchInput = styled.input`
  padding: 7px 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid grey;
  height: 100%;
  padding-left: 30px;
  width: 100%;

  ::placeholder {
    color: #dddddd;
  }
`;
export const HomeContainer = styled.div`
  width: 100%;
`;

export const StationsCircle = styled.div`
  width: 35px;
  border-radius: 50%;
  height: 35px;
  background-color: ${(props) => props.color};
`;

export const GridEmpty = styled.div``;
export const StationsHr = styled.div`
  display: flex;
  align-items: center;
`;

export const StationsEmployeesPhoto = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: -6px;
`;

export const StationsExtraEmployees = styled.div`
  width: 35px;
  border-radius: 50%;
  height: 35px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  border: 2px solid lightgrey;
`;

export const FigmaImage = styled.img`
  width: 100%;
`;
